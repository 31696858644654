import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Chip } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const TC_SkillsSection = ({ skills, isDetailsLoaded, getTextColor }) => {
  if (!skills || !isDetailsLoaded) return null;

  const skillsByLevel = Object.entries(skills).reduce((acc, [skill, level]) => {
    const category =
      level >= 80
        ? "Expert"
        : level >= 60
        ? "Advanced"
        : level >= 40
        ? "Intermediate"
        : "Basic";
    if (!acc[category]) acc[category] = [];
    acc[category].push({ name: skill, level });
    return acc;
  }, {});

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
        Skills
      </Typography>
      <AnimatePresence>
        {Object.entries(skillsByLevel).map(([category, skills]) => (
          <motion.div
            key={category}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
          >
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                {category}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {skills.map((skill) => (
                  <Chip
                    key={skill.name}
                    label={`${skill.name} (${skill.level}%)`}
                    size="small"
                    variant="outlined"
                    sx={{ color: getTextColor() }}
                  />
                ))}
              </Box>
            </Box>
          </motion.div>
        ))}
      </AnimatePresence>
    </Box>
  );
};

TC_SkillsSection.propTypes = {
  skills: PropTypes.objectOf(PropTypes.number),
  isDetailsLoaded: PropTypes.bool.isRequired,
  getTextColor: PropTypes.func.isRequired,
};

export default TC_SkillsSection;