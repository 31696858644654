import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    fetchUsers,
    fetchServiceRequest,
    updateServiceRequestStatus,
    updateRequestPriority,
    updateRequestAssignee,
    fetchTasks,
} from "../../ApiServices/ApiServices";
import NotesForm from "../Components/SvcNotesForm/NotesForm";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Stack,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import priority from "../Data/priority.json";
import statusData from "../Data/srStatus.json";

const Service = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { serviceID } = useParams();
    const licenseKey =
        "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";

    const [service, setService] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [currentUser, setUser] = useState("");
    const [userInfo, setUsers] = useState();
    const [tasks, setTasks] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState("");
    const [selectedAssignee, setSelectedAssignee] = useState("");
    const [life, setLife] = useState([]);
    const [configItem, setConfigItem] = useState("");
    const [tempConfigItem, setTempConfigItem] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                setUser(idToken.payload.email);
                const usersResponse = await fetchUsers(licenseKey);
                setUsers(usersResponse);
                const serviceResponse = await fetchServiceRequest(serviceID);
                setService(serviceResponse);
                const history = JSON.parse(serviceResponse?.record?.Lifespan);
                setLife(history.status);
                setSelectedStatus(serviceResponse?.record?.ServiceStatus || "");
                setSelectedPriority(serviceResponse?.record?.Priority || "");
                setSelectedAssignee(serviceResponse?.record?.AssignedTo ?? "");
                setConfigItem(serviceResponse?.record?.ConfigurationItem || "");

                const tasksData = await fetchTasks(serviceID);
                setTasks(Array.isArray(tasksData.body) ? tasksData.body : []);

                setStatusOptions(statusData.status);
            } catch (error) {
                console.log("Error:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [serviceID]);

    const handleStatusChange = async (newValue) => {
        setSelectedStatus(newValue);
        setLoading(true);
        try {
            await updateServiceRequestStatus(serviceID, newValue, currentUser);
            alert("Service Status Updated Successfully");
        } catch (error) {
            console.log("Error Updating Status:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePriorityChange = async (newValue) => {
        setSelectedPriority(newValue);
        setLoading(true);
        try {
            await updateRequestPriority(serviceID, newValue, currentUser);
            alert("Request Priority Updated Successfully");
        } catch (error) {
            console.log("Error Updating Priority:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAssignmentChange = async (newValue) => {
        setLoading(true);
        setSelectedAssignee(newValue);
        try {
            await updateRequestAssignee(serviceID, newValue, currentUser);
            alert("Assignee Updated");
        } catch (error) {
            console.log("Error Updating Assignee:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // Implement handleCIChange functionality here
            console.log("Handle Key Down");
        }
    };

    const getPriorityColor = (priorityText) => {
        const priorityObject = priority.find(
            (item) => item.priority === priorityText,
        );
        return priorityObject
            ? priorityObject.color
            : theme.palette.text.primary;
    };

    const getStatusColor = (statusText) => {
        const statusObject = statusData.status.find(
            (item) => item.name === statusText,
        );
        return statusObject ? statusObject.color : theme.palette.text.primary;
    };

    const getTaskStatusColor = (status) => {
        const statusObject = statusData.status.find(
            (item) => item.name === status,
        );
        return statusObject ? statusObject.color : theme.palette.text.primary;
    };

    const getTaskTypeColor = (type) => {
        switch (type) {
            case "Analysis":
                return "info";
            case "Development":
                return "secondary";
            case "Testing":
                return "error";
            default:
                return "default";
        }
    };

    const getTaskPriorityColor = (priorityText) => {
        const priorityObject = priority.find(
            (item) => item.priority === priorityText,
        );
        return priorityObject
            ? priorityObject.color
            : theme.palette.text.primary;
    };

    const getAssignmentGroupColor = (group) => {
        // You can define colors for specific groups or use a default color
        return "default";
    };

    const lastTimestamp = (service) => {
        const notesLog = JSON.parse(service.record.NotesLog);
        return notesLog.notes[notesLog.notes.length - 1].timestamp;
    };

    const statusColor = useMemo(
        () => getStatusColor(selectedStatus),
        [selectedStatus],
    );
    const priorityColor = useMemo(
        () => getPriorityColor(selectedPriority),
        [selectedPriority],
    );

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">
                                    Request Details
                                </Typography>
                                {[
                                    {
                                        label: "Request Type",
                                        value: service?.record?.RequestType,
                                    },
                                    {
                                        label: "Request Number",
                                        value: service?.record?.RequestNumber,
                                    },
                                    {
                                        label: "Organization",
                                        value: service?.record?.Organization,
                                    },
                                    {
                                        label: "Location",
                                        value: service?.record?.Location,
                                    },
                                    {
                                        label: "Floor",
                                        value: service?.record?.Floor,
                                    },
                                    {
                                        label: "Created",
                                        value: service?.record?.CreationTime,
                                    },
                                    {
                                        label: "Due Date",
                                        value: service?.record?.DueDate,
                                    },
                                    {
                                        label: "Last Message",
                                        value:
                                            service && lastTimestamp(service),
                                    },
                                ].map((item, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography>{item.label}:</Typography>
                                        <Typography
                                            color={
                                                theme.palette.mode === "light"
                                                    ? "#003d8c"
                                                    : [
                                                          `#e6f1ff`,
                                                          `#b0d2ff`,
                                                          `#8abcff`,
                                                          `#549eff`,
                                                          `#338bff`,
                                                          `#0064e8`,
                                                      ][index % 6]
                                            }
                                        >
                                            {item.value || "N/A"}
                                        </Typography>
                                    </Box>
                                ))}
                                <Typography>
                                    {service?.record?.RequestDescription ||
                                        "No description available"}
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={selectedStatus}
                                        onChange={(e) =>
                                            handleStatusChange(e.target.value)
                                        }
                                        sx={{
                                            color: statusColor,
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: statusColor,
                                                },
                                        }}
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem
                                                key={option.name}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Priority</InputLabel>
                                    <Select
                                        value={selectedPriority}
                                        onChange={(e) =>
                                            handlePriorityChange(e.target.value)
                                        }
                                        sx={{
                                            color: priorityColor,
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: priorityColor,
                                                },
                                        }}
                                    >
                                        {priority.map((option) => (
                                            <MenuItem
                                                key={option.priority}
                                                value={option.priority}
                                            >
                                                {option.priority}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Assigned To
                            </Typography>
                            <Typography>
                                Assignment Group:{" "}
                                {service?.record?.AssignedGroup}
                            </Typography>
                            {userInfo?.response && (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Assignee</InputLabel>
                                    <Select
                                        value={selectedAssignee}
                                        onChange={(e) =>
                                            handleAssignmentChange(
                                                e.target.value,
                                            )
                                        }
                                    >
                                        {userInfo.response.map((option) => (
                                            <MenuItem
                                                key={option.EmailAddress}
                                                value={option.EmailAddress}
                                            >
                                                {option.EmailAddress}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Requester Detail
                            </Typography>
                            <Typography>
                                {service?.record?.RequesterName || ""}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Service Log
                            </Typography>
                            {service && (
                                <NotesForm
                                    userEmail={currentUser}
                                    service={service}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Service Lifecycle
                            </Typography>
                            <List>
                                {life.map((entry, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${entry.status} - ${entry.timestamp}`}
                                            secondary={entry.user}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Optional Fields
                            </Typography>
                            <TextField
                                fullWidth
                                label="Enter Configuration Item Name"
                                value={tempConfigItem}
                                onChange={(e) =>
                                    setTempConfigItem(e.target.value)
                                }
                                onKeyDown={handleKeyDown}
                                margin="normal"
                            />
                            <Typography>
                                Current Item:{" "}
                                {service?.record?.ConfigurationItem}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Associated Tasks
                            </Typography>
                            {tasks.length > 0 ? (
                                tasks.map((task, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            mb: 2,
                                            p: 2,
                                            border: "1px solid",
                                            borderColor: "divider",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                {task.TaskNumber || "N/A"}
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() =>
                                                    navigate(
                                                        `/task/${task.TaskID}/${serviceID}`,
                                                    )
                                                }
                                                sx={{
                                                    position: "absolute",
                                                    top: 8,
                                                    right: 8,
                                                }}
                                            >
                                                Go
                                            </Button>
                                        </Box>
                                        <Typography>
                                            {task.TaskDescription ||
                                                "No description available"}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            mt={2}
                                            alignItems="center"
                                        >
                                            <Chip
                                                label={task.TaskStatus || "N/A"}
                                                sx={{
                                                    backgroundColor:
                                                        getTaskStatusColor(
                                                            task.TaskStatus,
                                                        ),
                                                    color: "white",
                                                }}
                                                size="small"
                                            />
                                            <Chip
                                                label={task.TaskType || "N/A"}
                                                color={getTaskTypeColor(
                                                    task.TaskType,
                                                )}
                                                size="small"
                                            />
                                            <Chip
                                                label={task.Priority || "N/A"}
                                                sx={{
                                                    backgroundColor:
                                                        getTaskPriorityColor(
                                                            task.Priority,
                                                        ),
                                                    color: "white",
                                                }}
                                                size="small"
                                            />
                                            <Chip
                                                label={
                                                    task.AssignmentGroup ||
                                                    "N/A"
                                                }
                                                color={getAssignmentGroupColor(
                                                    task.AssignmentGroup,
                                                )}
                                                size="small"
                                            />
                                        </Stack>
                                    </Box>
                                ))
                            ) : (
                                <Typography>
                                    No tasks associated with this service.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Service;
