import React, { useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import TC_Header from "./TC_Header";
import TC_SkillsSection from "./TC_SkillsSection";
import TC_ProjectList from "./TC_ProjectList";
import TC_CalendarDialog from "./TC_CalendarDialog";
import TC_Error from "./TC_Error";
import { calculateAvailability } from "./availabilityCalculator";

const TC_Main = ({
  technician,
  index,
  totalTechnicians,
  onAddMember,
  isSelected,
  startDate,
  endDate,
  onTechnicianUpdate,
}) => {
  const theme = useTheme();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getCardColor = () => {
    const hue = (index / Math.max(totalTechnicians - 1, 1)) * 240;
    return theme.palette.mode === "light"
      ? `hsla(${hue}, 60%, 95%, 0.2)`
      : `hsla(${hue}, 60%, 25%, 0.2)`;
  };

  const getTextColor = () => {
    const hue = (index / Math.max(totalTechnicians - 1, 1)) * 240;
    return theme.palette.mode === "light"
      ? `hsl(${hue}, 60%, 30%)`
      : `hsl(${hue}, 60%, 70%)`;
  };

  const handleDeleteProject = (projectNumber) => {
    if (!technician || !onTechnicianUpdate) return;

    const updatedSchedule = technician.Scheduled.filter(
      (schedule) => schedule.projectNumber !== projectNumber
    );

    const updatedTechnician = {
      ...technician,
      Scheduled: updatedSchedule,
      availability: calculateAvailability(
        updatedSchedule,
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD")
      ),
    };

    onTechnicianUpdate(updatedTechnician);
  };

  const handleTechnicianCalendarUpdate = (updatedTechnician) => {
    if (onTechnicianUpdate) {
      const updatedWithAvailability = {
        ...updatedTechnician,
        availability: calculateAvailability(
          updatedTechnician.Scheduled,
          startDate?.format("YYYY-MM-DD"),
          endDate?.format("YYYY-MM-DD")
        ),
      };
      onTechnicianUpdate(updatedWithAvailability);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Card
        sx={{
          marginBottom: 2,
          backgroundColor: getCardColor(),
          color: getTextColor(),
          transition: "all 0.3s ease",
        }}
      >
        <CardContent>
          <TC_Header
            technician={technician}
            getTextColor={getTextColor}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setIsDetailsLoaded={setIsDetailsLoaded}
            startDate={startDate}
            endDate={endDate}
            onAddMember={onAddMember}
            isSelected={isSelected}
            setIsCalendarOpen={setIsCalendarOpen}
          />
          
          {isExpanded && (
            <Box sx={{ px: 2 }}>
              <TC_SkillsSection
                skills={technician.Skills}
                isDetailsLoaded={isDetailsLoaded}
                getTextColor={getTextColor}
              />
              <TC_ProjectList
                scheduled={technician.Scheduled}
                isDetailsLoaded={isDetailsLoaded}
                onDelete={handleDeleteProject}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <TC_CalendarDialog
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
        technician={technician}
        onTechnicianUpdate={handleTechnicianCalendarUpdate}
      />

      <TC_Error error={error} onClose={() => setError(null)} />
    </motion.div>
  );
};

TC_Main.propTypes = {
  technician: PropTypes.shape({
    Email: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Role: PropTypes.string,
    Skills: PropTypes.objectOf(PropTypes.number),
    Scheduled: PropTypes.arrayOf(
      PropTypes.shape({
        projectNumber: PropTypes.string.isRequired,
        projectName: PropTypes.string,
        projectDates: PropTypes.shape({
          startDate: PropTypes.string.isRequired,
          endDate: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    availability: PropTypes.number,
    overallScore: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  totalTechnicians: PropTypes.number.isRequired,
  onAddMember: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  startDate: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }),
  endDate: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }),
  onTechnicianUpdate: PropTypes.func.isRequired,
};

TC_Main.defaultProps = {
  isSelected: false,
  startDate: null,
  endDate: null,
};

export default TC_Main;