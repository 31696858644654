import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchAuthSession } from "aws-amplify/auth";
import {
  Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, CircularProgress, TextField, InputAdornment, Select, MenuItem,
  LinearProgress, Grid, Dialog, DialogTitle, DialogContent, DialogActions,
  Container, Tooltip, IconButton, Chip, FormControl, InputLabel, Autocomplete
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  FilterList,
  FileDownloadOutlined,
  AddCircleOutlineOutlined
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import statusData from "../Data/srStatus.json";
import priorityData from "../Data/priority.json";
import {
  fetchServiceRequests,
  fetchTasks,
  updateServiceRequestStatus,
  updateRequestPriority,
  updateRequestAssignee,
  fetchUsers,
  fetchProjects
} from "../../ApiServices/ApiServices";

export default function Services() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(0);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState({});
  const [updateProgress, setUpdateProgress] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);
  const [filterState, setFilterState] = useState('all');
  const [currentUser, setCurrentUser] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [groupedServices, setGroupedServices] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);

  const licenseKey = "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";

  useEffect(() => {
    const initializeComponent = async () => {
      setLoading(true);
      setLoadingProgress(0);
      setLoadingTotal(0);
      
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        setCurrentUser(idToken.payload.email);
        
        // Fetch users
        const usersResponse = await fetchUsers(licenseKey);
        setUserInfo(usersResponse.response || []);
        
        // Fetch projects
        const projectsData = await fetchProjects();
        setProjects(projectsData || []);
        
        // Don't fetch services initially - wait for project selection
        setServices([]);
        
      } catch (error) {
        console.error("Error initializing component:", error);
        setServices([]);
      } finally {
        setLoading(false);
        setLoadingProgress(0);
        setLoadingTotal(0);
      }
    };
  
    initializeComponent();
  }, []);

  const handleProjectSelect = async (event, newValue) => {
    setSelectedProject(newValue);
    setLoading(true);
    setLoadingProgress(0);
    setLoadingTotal(0);
    
    try {
      if (!newValue?.ProjectNumber) {
        setServices([]);
        return;
      }
  
      // Get the project number
      const projectNumber = newValue.ProjectNumber;
      
      // Fetch service data using project number
      const servicesData = await fetchServiceRequests(projectNumber);
      
      if (servicesData && servicesData.records) {
        setServices(servicesData.records);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services for project:", error);
      setServices([]);
    } finally {
      setLoading(false);
      setLoadingProgress(0);
      setLoadingTotal(0);
    }
  };

  useEffect(() => {
    const grouped = services.reduce((acc, service) => {
      const requestType = service.RequestType || 'Unspecified';
      const organization = service.Organization || 'Unspecified';
      const serviceStatus = service.ServiceStatus || 'Unspecified';
      const projectName = service.ProjectName || 'Unspecified';

      const key = `${requestType}-${organization}-${serviceStatus}-${projectName}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(service);
      return acc;
    }, {});

    const groupedArray = Object.entries(grouped).map(([key, services]) => ({
      id: key,
      requestType: services[0].RequestType || 'Unspecified',
      organization: services[0].Organization || 'Unspecified',
      serviceStatus: services[0].ServiceStatus || 'Unspecified',
      projectName: services[0].ProjectName || 'Unspecified',
      services: services,
      count: services.length,
      assignmentGroup: services[0].AssignmentGroup || 'Unspecified'
    })).sort((a, b) => {
      const typeA = a.requestType?.toLowerCase() || '';
      const typeB = b.requestType?.toLowerCase() || '';
      return typeA.localeCompare(typeB);
    });

    setGroupedServices(groupedArray);
    setFilteredGroups(groupedArray);
  }, [services]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = useCallback(() => {
    setIsSearching(true);
    const lowerSearchTerm = searchTerm.toLowerCase();
    const filtered = groupedServices.filter(group =>
      group.requestType.toLowerCase().includes(lowerSearchTerm) ||
      group.organization.toLowerCase().includes(lowerSearchTerm) ||
      group.serviceStatus.toLowerCase().includes(lowerSearchTerm) ||
      group.projectName.toLowerCase().includes(lowerSearchTerm)
    );
    setFilteredGroups(filtered);
    setIsSearching(false);
  }, [searchTerm, groupedServices]);

  const handleUpdateProgress = (current, total) => {
    const progress = Math.round((current / total) * 100);
    setOverallProgress(progress);
  };

  const getStatusColor = (status) => {
    const statusObject = statusData.status.find(item => item.name === status);
    return statusObject ? statusObject.color : theme.palette.text.primary;
  };

  const getPriorityColor = (priorityName) => {
    const priority = priorityData.find(p => p.priority === priorityName);
    return priority ? priority.color : theme.palette.text.primary;
  };

  const handleFilterChange = () => {
    setFilterState(prevState => {
      if (prevState === 'all') return 'myRequests';
      if (prevState === 'myRequests') return 'assignedByMe';
      return 'all';
    });
  };

  const ServiceGroup = ({ group }) => {
    const [expanded, setExpanded] = useState(false);
    const [groupStatus, setGroupStatus] = useState('');
    const [updateCount, setUpdateCount] = useState('');
    const [groupAssignee, setGroupAssignee] = useState('');
    const [groupPriority, setGroupPriority] = useState('');
    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);

    const handleAccordionChange = async (event, isExpanded) => {
      setExpanded(isExpanded);
      if (isExpanded && tasks.length === 0) {
        setLoadingTasks(true);
        try {
          const tasksData = await Promise.all(
            group.services.map(service => fetchTasks(service.RequestID))
          );
          setTasks(tasksData.flatMap(data => data.body || []));
        } catch (error) {
          console.error("Error fetching tasks:", error);
        } finally {
          setLoadingTasks(false);
        }
      }
    };

    const handleGroupStatusChange = (event) => {
      setGroupStatus(event.target.value);
    };

    const handleGroupAssigneeChange = (event) => {
      setGroupAssignee(event.target.value);
    };

    const handleGroupPriorityChange = (event) => {
      setGroupPriority(event.target.value);
    };

    const handleUpdateCountChange = (event) => {
      const value = event.target.value.replace(/^0+/, '');
      const numValue = parseInt(value, 10);
      
      if (value === '' || (numValue >= 1 && numValue <= group.count)) {
        setUpdateCount(value);
      }
    };

    const handleBulkUpdate = async () => {
      if (updateCount === '' || (groupStatus === '' && groupAssignee === '' && groupPriority === '')) return;

      setIsUpdating(true);
      setUpdateDialogOpen(true);
      setOverallProgress(0);

      const servicesToUpdate = group.services.slice(0, parseInt(updateCount));
      setUpdatingStatus(prev => ({ ...prev, [group.id]: true }));
      setUpdateProgress(prev => ({ ...prev, [group.id]: 0 }));

      const updatedServices = [];
      for (let i = 0; i < servicesToUpdate.length; i++) {
        const service = servicesToUpdate[i];
        try {
          if (groupStatus !== '') {
            await updateServiceRequestStatus(service.RequestID, groupStatus, currentUser);
          }
          if (groupAssignee !== '') {
            await updateRequestAssignee(service.RequestID, groupAssignee, currentUser);
          }
          if (groupPriority !== '') {
            await updateRequestPriority(service.RequestID, groupPriority, currentUser);
          }
          updatedServices.push({ 
            ...service, 
            ServiceStatus: groupStatus !== '' ? groupStatus : service.ServiceStatus,
            AssignedTo: groupAssignee !== '' ? groupAssignee : service.AssignedTo,
            Priority: groupPriority !== '' ? groupPriority : service.Priority
          });
          handleUpdateProgress(i + 1, servicesToUpdate.length);
        } catch (error) {
          console.error(`Error updating service ${service.RequestID}:`, error);
          updatedServices.push(service);
        }
      }

      setServices(prevServices => 
        prevServices.map(s => {
          const updatedService = updatedServices.find(us => us.RequestID === s.RequestID);
          return updatedService || s;
        })
      );

      setUpdatingStatus(prev => ({ ...prev, [group.id]: false }));
      setUpdateCount('');
      setGroupStatus('');
      setGroupAssignee('');
      setGroupPriority('');
      setIsUpdating(false);
      setUpdateDialogOpen(false);
    };

    return (
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between', 
            alignItems: isMobile ? 'flex-start' : 'center', 
            width: '100%' 
          }}>
            <Typography sx={{ mb: isMobile ? 1 : 0 }}>
              {`${group.requestType} - ${group.organization} - ${group.projectName} - `}
              <span style={{ color: getStatusColor(group.serviceStatus) }}>{group.serviceStatus}</span>
              {` (${group.count} services)`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="text"
                inputMode="numeric"
                pattern="[1-9][0-9]*"
                value={updateCount}
                onChange={handleUpdateCountChange}
                placeholder={`1-${group.count}`}
                sx={{ width: 80, mr: 1 }}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
              <Select
                value={groupStatus}
                onChange={handleGroupStatusChange}
                displayEmpty
                onClick={(e) => e.stopPropagation()}
                disabled={isUpdating}
                sx={{ minWidth: 120, mr: 1 }}
              >
                <MenuItem value="" disabled>Update Status</MenuItem>
                {statusData.status.map((status) => (
                  <MenuItem key={status.id} value={status.name} style={{ color: status.color }}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={groupPriority}
                onChange={handleGroupPriorityChange}
                displayEmpty
                onClick={(e) => e.stopPropagation()}
                disabled={isUpdating}
                sx={{ minWidth: 120, mr: 1 }}
              >
                <MenuItem value="" disabled>Update Priority</MenuItem>
                {priorityData.map((priority) => (
                  <MenuItem key={priority.priority} value={priority.priority} style={{ color: priority.color }}>
                    {priority.priority}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={groupAssignee}
                onChange={handleGroupAssigneeChange}
                displayEmpty
                onClick={(e) => e.stopPropagation()}
                disabled={isUpdating}
                sx={{ minWidth: 120, mr: 1 }}
              >
                <MenuItem value="" disabled>Reassign</MenuItem>
                {userInfo.map((user) => (
                  <MenuItem key={user.EmailAddress} value={user.EmailAddress}>
                    {`${user.FirstName} ${user.LastName}`}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleBulkUpdate}
                disabled={(updateCount === '' || (groupStatus === '' && groupAssignee === '' && groupPriority === '')) || isUpdating}
              >
                Update
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" gutterBottom>Services</Typography>
          <TableContainer sx={{ overflowX: 'auto' }}>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  {['Request #', 'Description', 'Priority', 'Due Date', 'Assigned To', 'Status', 'Action'].map((header) => (
                    <TableCell key={header} sx={{ display: isMobile && header !== 'Request #' && header !== 'Action' ? 'none' : 'table-cell' }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {group.services.map((service) => (
                  <TableRow key={service.RequestID}>
                    <TableCell>{service.RequestNumber}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{service.RequestDescription}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                      <Chip 
                        label={service.Priority} 
                        sx={{ backgroundColor: getPriorityColor(service.Priority), color: 'white' }}
                      />
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{service.DueDate}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{service.AssignedTo}</TableCell>
                    <TableCell>
                      <Chip 
                        label={service.ServiceStatus} 
                        sx={{ backgroundColor: getStatusColor(service.ServiceStatus), color: 'white' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component={Link}
                        to={`/service/${service.RequestID}`}
                      >
                        Go
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Related Tasks</Typography>
          {loadingTasks ? (
            <CircularProgress />
          ) : tasks.length > 0 ? (
            <TableContainer sx={{ overflowX: 'auto' }}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    {['Task #', 'Description', 'Priority', 'Due Date', 'Assigned To', 'Status', 'Action'].map((header) => (
                      <TableCell key={header} sx={{ display: isMobile && header !== 'Task #' && header !== 'Action' ? 'none' : 'table-cell' }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.TaskID}>
                      <TableCell>{task.TaskNumber}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{task.TaskDescription}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                        <Chip 
                          label={task.Priority} 
                          sx={{ backgroundColor: getPriorityColor(task.Priority), color: 'white' }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{task.DueDate}</TableCell>
                      <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{task.AssignedTo}</TableCell>
                      <TableCell>
                        <Chip 
                          label={task.TaskStatus} 
                          sx={{ backgroundColor: getStatusColor(task.TaskStatus), color: 'white' }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component={Link}
                          to={`/task/${task.TaskID}/${task.RequestID}`}
                        >
                          Go
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No tasks associated with these services.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const downloadCSV = () => {
    const headers = [
      "RequestNumber",
      "RequestType",
      "RequestDescription",
      "Priority",
      "ServiceStatus",
      "RequestID",
      "AssignedTo",
      "RequesterName",
      "RequesterEmail",
      "CreationTime",
      "DueDate",
      "Organization",
      "Location",
      "AssignmentGroup",
      "ProjectName"
    ];

    const csvContent = [
      headers.join(","),
      ...services.map(service => 
        headers.map(header => {
          let cell = service[header] || "";
          cell = cell.toString().replace(/"/g, '""');
          return `"${cell}"`;
        }).join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "service_requests.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        {loadingTotal > 0 && (
          <Box sx={{ mt: 2, width: '300px', textAlign: 'center' }}>
            <LinearProgress 
              variant="determinate" 
              value={(loadingProgress / loadingTotal) * 100} 
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Loading records: {loadingProgress} / {loadingTotal}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 3, px: { xs: 1, sm: 2, md: 3 } }}>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Service Requests</Typography>
            <Box>
              <Tooltip title={filterState === 'all' ? "My Requests" : filterState === 'myRequests' ? "Assigned To" : "All"}>
                <IconButton onClick={handleFilterChange}>
                  <FilterList />
                </IconButton>
              </Tooltip>
              <Tooltip title="Create New Request">
                <IconButton onClick={() => navigate('/new-service')}>
                  <AddCircleOutlineOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export All">
                <IconButton onClick={downloadCSV}>
                  <FileDownloadOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Autocomplete
              value={selectedProject}
              onChange={handleProjectSelect}
              options={projects}
              getOptionLabel={(option) => option ? `${option.ProjectName}` : ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Project"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Request Type, Organization, Status, or Project Name..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              disabled={isSearching}
              sx={{ ml: 2 }}
            >
              {isSearching ? <CircularProgress size={24} /> : 'Search'}
            </Button>
          </Box>

          <Grid container spacing={2}>
            {filteredGroups.map((group) => (
              <Grid item xs={12} key={group.id}>
                <ServiceGroup group={group} />
              </Grid>
            ))}
          </Grid>
          {filteredGroups.length === 0 && (
            <Typography>No matching services found</Typography>
          )}
        </Paper>
      </Box>

      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
        <DialogTitle>Updating Services</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={overallProgress} />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              {`${Math.round(overallProgress)}%`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateDialogOpen(false)} color="primary" disabled={isUpdating}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}