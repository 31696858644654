import React from 'react';
import {
    Box,
    Chip,
    IconButton,
    Tooltip
} from '@mui/material';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SyncIcon from '@mui/icons-material/Sync';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import ErrorIcon from '@mui/icons-material/Error';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PeopleIcon from '@mui/icons-material/People';

const ProjectConnectionStatus = ({
    connectionStatus,
    techniciansCount,
    isLoadingTechnicians,
    techniciansError,
    isConnecting,
    onDisconnect,
    onReconnect
}) => {
    const getConnectionStatusConfig = (status) => {
        const configs = {
            connected: {
                icon: <SignalWifiStatusbar4BarIcon />,
                label: 'Connected',
                color: 'success'
            },
            connecting: {
                icon: <SyncIcon className="rotating" />,
                label: 'Connecting',
                color: 'warning'
            },
            disconnected: {
                icon: <SignalWifiOffIcon />,
                label: 'Disconnected',
                color: 'error'
            },
            error: {
                icon: <ErrorIcon />,
                label: 'Connection Error',
                color: 'error'
            }
        };

        return configs[status] || configs.disconnected;
    };

    const getTechnicianStatusConfig = () => {
        if (isLoadingTechnicians) {
            return {
                icon: <SyncIcon className="rotating" />,
                label: 'Loading Technicians',
                color: 'warning'
            };
        }
        if (techniciansError) {
            return {
                icon: <ErrorIcon />,
                label: 'Technicians Error',
                color: 'error'
            };
        }
        return {
            icon: <PeopleIcon />,
            label: `${techniciansCount} Technicians`,
            color: 'success'
        };
    };

    const statusConfig = getConnectionStatusConfig(connectionStatus);
    const technicianConfig = getTechnicianStatusConfig();

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Chip
                icon={statusConfig.icon}
                label={statusConfig.label}
                color={statusConfig.color}
                variant="outlined"
            />
            <Chip
                icon={technicianConfig.icon}
                label={technicianConfig.label}
                color={technicianConfig.color}
                variant="outlined"
            />
            {connectionStatus === 'connected' && (
                <Tooltip title="Disconnect">
                    <IconButton 
                        onClick={onDisconnect}
                        color="error"
                        size="small"
                        disabled={isConnecting}
                    >
                        <PowerSettingsNewIcon />
                    </IconButton>
                </Tooltip>
            )}
            {connectionStatus === 'disconnected' && (
                <Tooltip title="Reconnect">
                    <IconButton 
                        onClick={onReconnect}
                        color="primary"
                        size="small"
                        disabled={isConnecting}
                    >
                        <PowerSettingsNewIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default ProjectConnectionStatus;