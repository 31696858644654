import React, { useState, useEffect } from "react";
import { downloadData } from "aws-amplify/storage";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { checkProjectAvailability } from "../../../ApiServices/ApiServices";

const ProjectDetailsForm = ({ projectData, setProjectData, isEditMode }) => {
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [locations, setLocations] = useState({});
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    fetchLocationsData();
  }, []);

  const fetchLocationsData = async () => {
    console.log('Fetching locations data from S3');
    setIsLoadingLocations(true);
    setLocationError(null);

    try {
      const downloadResult = await downloadData({
        key: 'databases/locations.json'
      }).result;

      const text = await downloadResult.body.text();
      const locationsData = JSON.parse(text);
      
      console.log('Locations data fetched successfully', {
        companies: Object.keys(locationsData).length
      });
      
      setLocations(locationsData);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setLocationError('Failed to load location data. Please try again later.');
    } finally {
      setIsLoadingLocations(false);
    }
  };

  const handleProjectNameChange = (e) => {
    const input = e.target.value;
    if (input.startsWith("PRJ-") && input.length <= 64) {
      console.log('Project name updated:', input);
      setProjectData(prevData => ({ ...prevData, projectName: input }));
      setIsAvailable(null);
    }
  };

  const handleProjectDescriptionChange = (e) => {
    const input = e.target.value;
    if (input.length <= 512) {
      console.log('Project description updated, length:', input.length);
      setProjectData(prevData => ({ ...prevData, projectDescription: input }));
    }
  };

  const checkAvailability = async () => {
    console.log('Checking project name availability:', projectData.projectName);
    setIsChecking(true);
    try {
      const response = await checkProjectAvailability(projectData.projectName);
      setIsAvailable(response.body.available);
      console.log('Availability check result:', response.body.available);
    } catch (error) {
      console.error("Error checking availability:", error);
      setIsAvailable(false);
    } finally {
      setIsChecking(false);
    }
  };

  const handleCustomerChange = (event) => {
    const selectedCustomer = event.target.value;
    console.log('Customer selected:', selectedCustomer);
    setProjectData(prevData => ({
      ...prevData,
      customerName: selectedCustomer,
      selectedBuilding: "",
      selectedFloor: "",
    }));
  };

  if (isLoadingLocations) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (locationError) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {locationError}
      </Alert>
    );
  }

  return (
    <>
      <TextField
        fullWidth
        label="Project Name"
        value={projectData.projectName}
        onChange={handleProjectNameChange}
        margin="normal"
        helperText={`${64 - projectData.projectName.length} characters remaining`}
        inputProps={{
          maxLength: 64,
        }}
      />
      
      <Box display="flex" alignItems="center" mt={2}>
        <Button
          variant="contained"
          onClick={checkAvailability}
          disabled={projectData.projectName.length <= 4 || isChecking}
          sx={{ mr: 2 }}
        >
          Check if Available
        </Button>
        {isChecking && <CircularProgress size={24} />}
        {!isChecking && isAvailable !== null && (
          <Typography color={isAvailable ? "green" : "red"}>
            {isAvailable ? "Available" : "Unavailable"}
          </Typography>
        )}
      </Box>

      <TextField
        fullWidth
        label="Project Owner"
        value={projectData.projectOwner}
        margin="normal"
        disabled
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="customer-select-label">Customer</InputLabel>
        <Select
          labelId="customer-select-label"
          value={projectData.customerName}
          label="Customer"
          onChange={handleCustomerChange}
        >
          {Object.keys(locations).map((customer) => (
            <MenuItem key={customer} value={customer}>
              {customer}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Project Description"
        multiline
        rows={4}
        value={projectData.projectDescription}
        onChange={handleProjectDescriptionChange}
        margin="normal"
        helperText={`${512 - projectData.projectDescription.length} characters remaining`}
        inputProps={{
          maxLength: 512,
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <DatePicker
              label="Start Date"
              value={projectData.startDate}
              onChange={(newValue) => {
                console.log('Start date updated:', newValue);
                setProjectData(prevData => ({ ...prevData, startDate: newValue }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="End Date"
              value={projectData.endDate}
              onChange={(newValue) => {
                console.log('End date updated:', newValue);
                setProjectData(prevData => ({ ...prevData, endDate: newValue }));
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default ProjectDetailsForm;