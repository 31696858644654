import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Avatar,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  useTheme,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WarningIcon from "@mui/icons-material/Warning";

const TC_Header = ({
  technician,
  getTextColor,
  isExpanded,
  setIsExpanded,
  setIsDetailsLoaded,
  startDate,
  endDate,
  onAddMember,
  isSelected,
  setIsCalendarOpen,
}) => {
  const theme = useTheme();

  const handleExpandClick = () => {
    if (!isExpanded) {
      setIsDetailsLoaded(true);
    }
    setIsExpanded(!isExpanded);
  };

  const handleCalendarClick = (e) => {
    e.stopPropagation();
    if (technician.Scheduled?.length > 0 || !isSelected) {
      setIsCalendarOpen(true);
    }
  };

  const getTooltipMessage = () => {
    if (isSelected) return "Already Added";
    if (!startDate || !endDate)
      return "Please select project start and end dates first";
    return "Add to Project";
  };

  const isAddButtonDisabled = isSelected || !startDate || !endDate;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            width: 60,
            height: 60,
            bgcolor: getTextColor(),
          }}
        >
          {technician.Name.split(" ")
            .map((n) => n[0])
            .join("")}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Typography variant="h6">{technician.Name}</Typography>
        <Typography variant="body1">{technician.Role}</Typography>
        <Typography variant="body2">{technician.Email}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color:
                technician.availability < 100
                  ? theme.palette.warning.main
                  : "inherit",
            }}
          >
            Project Availability: {technician.availability.toFixed(1)}%
          </Typography>
          {technician.availability < 100 && (
            <Tooltip
              title={`This technician has schedule conflicts during the project period${
                technician.Scheduled
                  ? `. Current projects: ${technician.Scheduled.length}`
                  : ""
              }`}
            >
              <WarningIcon
                fontSize="small"
                sx={{
                  color: theme.palette.warning.main,
                  verticalAlign: "middle",
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Tooltip title={getTooltipMessage()}>
            <span>
              <IconButton
                sx={{ color: getTextColor() }}
                onClick={() => onAddMember(technician)}
                disabled={isAddButtonDisabled}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              technician.Scheduled?.length
                ? "View Schedule"
                : "No schedules available"
            }
          >
            <span>
              <IconButton
                sx={{ color: getTextColor() }}
                onClick={handleCalendarClick}
                disabled={!technician.Scheduled?.length && isSelected}
              >
                <EventAvailableIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            width: 80,
            height: 80,
          }}
        >
          <CircularProgress
            variant="determinate"
            value={technician.overallScore || 0}
            size={80}
            thickness={4}
            sx={{ color: getTextColor() }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" component="div">
              {`${Math.round(technician.overallScore || 0)}%`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <IconButton onClick={handleExpandClick} sx={{ color: getTextColor() }}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

TC_Header.propTypes = {
  technician: PropTypes.object.isRequired,
  getTextColor: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  setIsDetailsLoaded: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onAddMember: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setIsCalendarOpen: PropTypes.func.isRequired,
};

export default TC_Header;