import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
// Amplify.configure(config);

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: "us-east-2_oaGXn36jK", // ID from (Cognito > User Pools > Your User Pool > User pool ID)
            userPoolClientId: "b2ouo65ecu82ific2r97cgcoe", // App client from (Cognito > User Pools > Your User Pool > App clients)
            identityPoolId: "us-east-2:e2061c14-6f31-4b43-945b-e76f77bc44c2", // Must use an identity pool that has the same userPoolClientId in the list of Identity Providers (Cognito > Identity Pools > Your Identity Pool > User Access > Identity Providers)
            loginWith: {
                email: true,
            },
            signUpVerificationMethod: "code",
            userAttributes: {
                email: {
                    required: true,
                },
            },
            allowGuestAccess: false,
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
                requireSpecialCharacters: true,
            },
        },
    },
    Storage: {
        S3: {
            bucket: "ciservicedesk",
            region: "us-east-2",
            buckets: {
                // List of buckets you want to support. Must include "default" bucket info if provided above
                // The key can be anything you want, just a friendly name to reference in other parts of the code.
                CiServiceDesk: {
                    bucketName: "ciservicedesk",
                    region: "us-east-2",
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
