import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const TechnicianCalendar = lazy(() => import("./TechnicianCalendar"));

const TC_CalendarDialog = ({ isOpen, onClose, technician, onTechnicianUpdate }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        Schedule for {technician.Name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <DeleteIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="400px"
            >
              <CircularProgress />
            </Box>
          }
        >
          <TechnicianCalendar
            technician={technician}
            isStandalone={false}
            onTechnicianUpdate={onTechnicianUpdate}
          />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};

TC_CalendarDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  technician: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Scheduled: PropTypes.array,
  }).isRequired,
  onTechnicianUpdate: PropTypes.func.isRequired,
};

export default TC_CalendarDialog;