import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Alert,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import eServicesDatabase from "../Data/eServicesDatabase.json";
import eTasksDatabase from "../Data/eTasksDatabase.json";

const BuildingSelector = ({
  buildings,
  selectedBuilding,
  selectedZone,
  selectedFloor,
  onBuildingChange,
  onZoneChange,
  onFloorChange,
  serviceQuantity,
  onQuantityChange,
}) => {
  const getFloorOptions = (floorCount) => {
    if (!floorCount) return [1]; // Default to 1 if no floors specified
    return Array.from({ length: floorCount }, (_, i) => i + 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Building</InputLabel>
          <Select
            value={selectedBuilding || ""}
            onChange={(e) => onBuildingChange(e.target.value)}
            label="Building"
          >
            {buildings?.map((building) => (
              <MenuItem key={building["Building Number"]} value={building}>
                {building["Building Name"]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {selectedBuilding?.Zone && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Zone</InputLabel>
            <Select
              value={selectedZone}
              onChange={(e) => onZoneChange(e.target.value)}
              label="Zone"
            >
              <MenuItem value={selectedBuilding.Zone}>
                {selectedBuilding.Zone}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Floor</InputLabel>
          <Select
            value={selectedFloor || 1}
            onChange={(e) => onFloorChange(e.target.value)}
            label="Floor"
          >
            {getFloorOptions(selectedBuilding?.Floors || 1).map((floor) => (
              <MenuItem key={floor} value={floor}>
                Floor {floor}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Quantity"
          type="number"
          value={serviceQuantity}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value >= 1 && value <= 9999) {
              onQuantityChange(value);
            }
          }}
          InputProps={{
            inputProps: { min: 1, max: 9999 },
          }}
          helperText="Enter quantity (1-9999)"
        />
      </Grid>
    </Grid>
  );
};

const ServiceDesign = ({ onServiceChange, availableBuildings }) => {
  const [services, setServices] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [serviceQuantity, setServiceQuantity] = useState(1);

  const theme = useTheme();

  useEffect(() => {
    try {
      const flattenedServices = eServicesDatabase.Categories.flatMap(
        (category) => {
          const categoryName = Object.keys(category)[0];
          return category[categoryName].map((service) => ({
            ...service,
            category: categoryName,
          }));
        }
      );

      setServices(flattenedServices);
      setTasks(eTasksDatabase.Tasks);
    } catch (error) {
      setError("Failed to process services and tasks data");
      console.error("Error processing data:", error);
    }
  }, []);

  const filteredTasks = tasks.filter(
    (task) =>
      task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleServiceSelect = (service) => {
    setCurrentService(service);
    setCurrentTasks([]);
    setIsEditing(false);
  };

  const handleEditService = (serviceEntry) => {
    setCurrentService(serviceEntry.service);
    setCurrentTasks(serviceEntry.tasks);
    setServiceQuantity(serviceEntry.quantity || 1);
    if (serviceEntry.location) {
      setSelectedBuilding(serviceEntry.location.building);
      setSelectedZone(serviceEntry.location.zone || "");
      setSelectedFloor(serviceEntry.location.floor || "");
    }
    setIsEditing(true);
    setIsAddDialogOpen(true);
  };

  const handleTaskToggle = (task) => {
    setCurrentTasks((current) => {
      const exists = current.find((t) => t.ID === task.ID);
      if (exists) {
        return current.filter((t) => t.ID !== task.ID);
      }
      return [...current, task];
    });
  };

  const handleAddService = () => {
    if (!currentService || currentTasks.length === 0) {
      setError("Please select a service and at least one task");
      return;
    }

    const serviceEntry = {
      service: currentService,
      tasks: currentTasks,
      quantity: serviceQuantity,
      location: selectedBuilding
        ? {
            building: selectedBuilding["Building Name"],
            buildingNumber: selectedBuilding["Building Number"],
            zone: selectedZone || undefined,
            floor: selectedFloor || undefined,
            buildingCoordinator: selectedBuilding["Building Coordinator"],
            coordinatorEmail: selectedBuilding["Email"],
            coordinatorPhone: selectedBuilding["Phone"],
          }
        : undefined,
    };

    setSelectedServices((current) => {
      const newServices = isEditing
        ? current.map((s) =>
            s.service.serviceID === currentService.serviceID ? serviceEntry : s
          )
        : [...current, serviceEntry];

      onServiceChange?.(newServices);
      return newServices;
    });

    // Reset selections
    setSelectedBuilding(null);
    setSelectedZone("");
    setSelectedFloor("");
    setIsAddDialogOpen(false);
    setCurrentService(null);
    setCurrentTasks([]);
    setIsEditing(false);
    setError(null);
  };

  const handleRemoveService = (serviceID) => {
    setSelectedServices((current) => {
      const newServices = current.filter(
        (s) => s.service.serviceID !== serviceID
      );
      onServiceChange?.(newServices);
      return newServices;
    });
  };
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Project Services
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddDialogOpen(true)}
        >
          Add Service
        </Button>
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {selectedServices.length > 0 ? (
        <Grid container spacing={2} sx={{ mb: 2 }}>
{selectedServices.map((serviceEntry) => (
    <Grid item xs={12} key={serviceEntry.service.serviceID}>
        <Card>
            <CardHeader
                // Update title to include quantity
                title={`${serviceEntry.service['Service Type']} (${serviceEntry.quantity} ${serviceEntry.quantity > 1 ? 'services' : 'service'})`}
                subheader={`${serviceEntry.service.category} • ${serviceEntry.tasks.length} tasks`}
                action={
                    <Box>
                        <IconButton 
                            onClick={() => handleEditService(serviceEntry)}
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton 
                            onClick={() => handleRemoveService(serviceEntry.service.serviceID)}
                            color="error"
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {serviceEntry.service.Description}
                </Typography>
                {serviceEntry.location && (
                    <Box sx={{ mt: 2, mb: 2, backgroundColor: theme.palette.action.hover, p: 2, borderRadius: 1 }}>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LocationOnIcon fontSize="small" />
                            Location Details:
                        </Typography>
                        {/* Add quantity to location details */}
                        <Typography variant="body2" color="text.secondary">
                            <strong>Quantity:</strong> {serviceEntry.quantity} {serviceEntry.quantity > 1 ? 'services' : 'service'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {serviceEntry.location.building}
                            {serviceEntry.location.floor && ` - Floor ${serviceEntry.location.floor}`}
                            {serviceEntry.location.zone && ` - Zone ${serviceEntry.location.zone}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Coordinator: {serviceEntry.location.buildingCoordinator}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Contact: {serviceEntry.location.coordinatorEmail} 
                            {serviceEntry.location.coordinatorPhone && ` • ${serviceEntry.location.coordinatorPhone}`}
                        </Typography>
                    </Box>
                )}
                <Box sx={{ mt: 1 }}>
                    {serviceEntry.tasks.map((task) => (
                        <Chip
                            key={task.ID}
                            label={task.name}
                            size="small"
                            sx={{ mr: 1, mb: 1 }}
                        />
                    ))}
                </Box>
            </CardContent>
        </Card>
    </Grid>
))}
        </Grid>
      ) : (
        <Alert severity="info" sx={{ mb: 2 }}>
          No services added to the project yet. Click 'Add Service' to begin.
        </Alert>
      )}

      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? "Edit Service" : "Add New Service"}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <SettingsIcon fontSize="small" />
                  Available Services
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search services..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <List
                  dense
                  sx={{
                    maxHeight: "400px",
                    overflow: "auto",
                    pr: 2,
                    "& .MuiButton-root:hover": {
                      "& .MuiTypography-root": {
                        color: "common.white",
                      },
                    },
                  }}
                >
                  {services
                    .filter(
                      (service) =>
                        service["Service Type"]
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        service.Description.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        ) ||
                        service.category
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .map((service) => (
                      <ListItem
                        key={service.serviceID}
                        disablePadding
                        sx={{ mb: 1 }}
                      >
                        <Button
                          fullWidth
                          variant={
                            currentService?.serviceID === service.serviceID
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleServiceSelect(service)}
                          sx={{
                            textAlign: "left",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            p: 2,
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle2">
                              {service["Service Type"]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {service.Description}
                            </Typography>
                          </Box>
                        </Button>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: "bold" }}
                >
                  Available Tasks
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search tasks..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ mb: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {currentService ? (
                  <List
                    dense
                    sx={{
                      maxHeight: "400px",
                      overflow: "auto",
                      pr: 2,
                    }}
                  >
                    {filteredTasks.map((task) => {
                      const isSelected = currentTasks.some(
                        (t) => t.ID === task.ID
                      );
                      return (
                        <ListItem
                          key={task.ID}
                          sx={{
                            mb: 1,
                            backgroundColor: isSelected
                              ? theme.palette.action.selected
                              : "transparent",
                            borderRadius: 1,
                            "&:hover": {
                              backgroundColor: isSelected
                                ? theme.palette.action.selected
                                : theme.palette.action.hover,
                            },
                          }}
                        >
                          <ListItemText
                            primary={task.name}
                            secondary={task.description}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip
                              title={isSelected ? "Remove Task" : "Add Task"}
                            >
                              <IconButton
                                edge="end"
                                onClick={() => handleTaskToggle(task)}
                                color={isSelected ? "primary" : "default"}
                              >
                                {isSelected ? (
                                  <RemoveCircleOutlineIcon />
                                ) : (
                                  <AddCircleOutlineIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: "center", py: 4 }}
                  >
                    Select a service to customize tasks
                  </Typography>
                )}
              </Box>
            </Grid>

            {currentService && (
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: "bold" }}
                >
                  Location Details
                </Typography>
                <BuildingSelector
                  buildings={availableBuildings}
                  selectedBuilding={selectedBuilding}
                  selectedZone={selectedZone}
                  selectedFloor={selectedFloor}
                  onBuildingChange={setSelectedBuilding}
                  onZoneChange={setSelectedZone}
                  onFloorChange={setSelectedFloor}
                  onQuantityChange={setServiceQuantity}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleAddService}
            variant="contained"
            disabled={!currentService || currentTasks.length === 0}
          >
            {isEditing ? "Update Service" : "Add Service"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServiceDesign;
