import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';

// Add the plugins to dayjs
dayjs.extend(isBetween);
dayjs.extend(minMax);

/**
 * Calculates the number of overlapping days between project dates and schedule dates
 */
const calculateDateOverlap = (projectStart, projectEnd, scheduleStart, scheduleEnd) => {
    // Add validation for null dates at the start
    if (!projectStart || !projectEnd) {
        return 0; // No overlap if project dates aren't set
    }
    
    const start = dayjs.max(dayjs(projectStart), dayjs(scheduleStart));
    const end = dayjs.min(dayjs(projectEnd), dayjs(scheduleEnd));
    
    if (end.isBefore(start)) {
        return 0; // No overlap
    }
    
    return end.diff(start, 'day') + 1;
};

/**
 * Calculates availability percentage based on project dates and scheduled commitments
 */
const calculateAvailability = (startDate, endDate, scheduledDates) => {
        if (!startDate || !endDate) return 100;
        
        // Convert dates to dayjs objects if they aren't already
        const projectStart = dayjs(startDate);
        const projectEnd = dayjs(endDate);
        
        // Calculate total project duration in days
        const totalProjectDays = projectEnd.diff(projectStart, 'day') + 1;
        
        if (!scheduledDates || scheduledDates.length === 0) {
            return 100; // Fully available if no scheduled dates
        }
    
        let overlappingDays = 0;
    
        scheduledDates.forEach(schedule => {
            const scheduleStart = dayjs(schedule.projectDates.startDate);
            const scheduleEnd = dayjs(schedule.projectDates.endDate);
    
            // Check if schedule overlaps with project dates
            if (scheduleEnd.isBefore(projectStart) || scheduleStart.isAfter(projectEnd)) {
                return; // No overlap
            }
    
            // Calculate overlap
            const overlapStart = dayjs.max(projectStart, scheduleStart);
            const overlapEnd = dayjs.min(projectEnd, scheduleEnd);
            const daysOverlap = overlapEnd.diff(overlapStart, 'day') + 1;
            
            overlappingDays += Math.max(0, daysOverlap);
        });
    
        // Calculate availability percentage
        const availabilityPercentage = ((totalProjectDays - overlappingDays) / totalProjectDays) * 100;
        
        // Ensure the percentage is between 0 and 100
        return Math.max(0, Math.min(100, availabilityPercentage));
    };

/**
 * Updates schedules for selected members when project dates change
 */
const updateMemberSchedules = (selectedMembers, projectNumber, newStartDate, newEndDate) => {
    return selectedMembers.map(member => {
        const updatedSchedule = member.Scheduled.map(schedule => {
            if (schedule.projectNumber === projectNumber) {
                return {
                    ...schedule,
                    projectDates: {
                        startDate: newStartDate,
                        endDate: newEndDate
                    }
                };
            }
            return schedule;
        });

        // Recalculate availability with updated schedule
        const availability = calculateAvailability(
            newStartDate,
            newEndDate,
            updatedSchedule,
            projectNumber
        );

        return {
            ...member,
            Scheduled: updatedSchedule,
            availability
        };
    });
};

/**
 * Handles date range changes for projects, updating all affected technicians
 */
const handleDateRangeChange = (newStartDate, newEndDate, ws, technicians, selectedMembers, projectNumber) => {
    // First update selected members' schedules
    const updatedMembers = updateMemberSchedules(
        selectedMembers,
        projectNumber,
        newStartDate,
        newEndDate
    );

    // Update availability for all technicians
    const updatedTechnicians = technicians.map(tech => {
        // If tech is in selected members, use the updated schedule
        const selectedMember = updatedMembers.find(member => member.Email === tech.Email);
        if (selectedMember) {
            return selectedMember;
        }

        // Otherwise recalculate availability with new project dates
        const availability = calculateAvailability(
            newStartDate,
            newEndDate,
            tech.Scheduled,
            projectNumber
        );

        return {
            ...tech,
            availability
        };
    });

    // Send updates via WebSocket for selected members
    if (ws?.readyState === WebSocket.OPEN) {
        updatedMembers.forEach(member => {
            ws.send(JSON.stringify({
                action: 'updatetech',
                data: {
                    technician: member
                }
            }));
        });
    }

    return {
        updatedMembers,
        updatedTechnicians
    };
};

export {
    calculateAvailability,
    updateMemberSchedules,
    handleDateRangeChange
};