import React from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";

const TC_Error = ({ error, onClose }) => {
  if (!error) return null;

  return (
    <Alert severity="error" onClose={onClose}>
      {error}
    </Alert>
  );
};

TC_Error.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default TC_Error;