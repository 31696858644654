import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainUI from "./components/UI2/Menu/MainUI";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { View, Authenticator } from "@aws-amplify/ui-react";

import { Amplify } from "aws-amplify";
import { UserProvider } from "./UserContext";

import { Service } from "aws-sdk";
import ServicePortal from "./components/UI2/CustomerPortal/components/ServicePortal";

const formFields = {
    signIn: {
        username: {
            label: "Email",
            placeholder: "Enter your email",
        },
    },
    signUp: {
        username: {
            label: "Email",
            isRequired: true,
        },
        password: {
            label: "Password:",
            placeholder: "Enter your Password:",
            isRequired: true,
        },
        confirm_password: {
            label: "Confirm Password:",
        },
        given_name: {
            label: "First Name",
            placeholder: "Enter your First Name",
            isRequired: true,
        },
        family_name: {
            label: "Last Name",
            placeholder: "Enter your Last Name",
            isRequired: true,
        },
        phone_number: {
            isRequired: true,
        },
    },
};

function App() {
    const [mode, setMode] = useState("light");

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };

    const theme = createTheme({
        palette: {
            mode,
            ...(mode === "light"
                ? {
                      // Light mode colors
                      primary: {
                          main: "#1976d2", // Default blue color for light mode
                      },
                      background: {
                          default: "#EFEFEF",
                          paper: "#FFFFFF",
                      },
                  }
                : {
                      // Dark mode colors
                      primary: {
                          main: "#1C71B4", // New dark blue color for dark mode
                      },
                  }),
        },
        transitions: {
            create: () => "all 0.3s ease",
        },
    });

    return (
        <UserProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Authenticator.Provider>
                    <Authenticator
                        formFields={formFields}
                        variation="modal"
                        hideSignUp
                    >
                        <Router>
                            <View>
                                <MainUI
                                    appName="Cloud Ingenuity Service Desk"
                                    toggleColorMode={toggleColorMode}
                                    colorMode={mode}
                                />
                            </View>
                        </Router>
                    </Authenticator>
                </Authenticator.Provider>
            </ThemeProvider>
        </UserProvider>
    );
}

export default App;
