import { useAuthenticator } from "@aws-amplify/ui-react";
import {
    fetchAuthSession,
    fetchUserAttributes,
    getCurrentUser,
} from "aws-amplify/auth";
import AWS from "aws-sdk";
import { downloadData, getUrl } from "aws-amplify/storage";

const getCurrentUserInfo = async () => {
    const { username, userId: id } = await getCurrentUser();

    const attributes = fetchUserAttributes();

    return {
        id,
        username,
        attributes,
    };
};

const ensureUserExists = async (license) => {
    try {
        console.log("Ensuring User Exists...");
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const currentUserInfo = await (await getCurrentUserInfo()).attributes;
        console.log(currentUserInfo);

        const payload = {
            license: license,
            emailAddress: currentUserInfo.email,
            firstName: currentUserInfo.given_name,
            lastName: currentUserInfo.family_name,
            phoneNumber: currentUserInfo.phone_number,
        };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/ensure-user-exists";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log("Response: ", responseData);
        } else {
            throw new Error("API Error: " + response.statusText);
        }

        return true;
    } catch (error) {
        console.log("Error:", error);
        throw error;
    }
};

const fetchUsers = async (licenseKey) => {
    try {
        console.log("Getting Users...");
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = {
            license: licenseKey,
        };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/get-all-users";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log("Users Retreived: ", responseData);

            // Process the responseData array to clean up the "Phone" property
            responseData.response.forEach((user) => {
                if (
                    user.Phone &&
                    user.Phone.startsWith("+") &&
                    user.Phone.indexOf("+", 1) !== -1
                ) {
                    user.Phone = user.Phone.slice(user.Phone.indexOf("+", 1));
                }
                user.Phone = user.Phone.replace(
                    /(\+\d{1,2})(\d{3})(\d{3})(\d{4})/,
                    "$1 ($2) $3-$4",
                );
            });

            return responseData;
        } else {
            throw new Error("API Error: " + response.statusText);
        }
    } catch (error) {
        console.log("Error:", error);
        throw error;
    }
};

const fetchIncident = async (incidentID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = {
            IncidentID: incidentID,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getIncident";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to fetch Incident");
        }
    } catch (error) {
        console.error("Error fetching service request:", error);
        throw error;
    }
};

const fetchIncidents = async () => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getIncidents";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
        });
        if (response.ok) {
            const data = await response.json();
            console.log("Incidents List: ", data);
            return data;
        } else {
            throw new Error("Failed to Fetch Incidents");
        }
    } catch (error) {
        console.error("Error Fetching Incidents:", error);
        throw error; // Re-throw the error to handle it elsewhere if needed
    }
};

//
const updateTaskStatus = async (taskID, requestID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            TaskID: taskID,
            RequestID: requestID,
            TaskStatus: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/updateTaskLifespan";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        console.log("Status Updated for Task:", taskID);
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updateStatus = async (incidentID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            IncidentID: incidentID,
            Status: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/incident";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updateAssignee = async (incidentID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            IncidentID: incidentID,
            AssignedTo: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/updateIncidentAssignee";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        console.log("Group Assignee Updated: ", newValue);
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updateTaskAssignee = async (taskID, requestID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = {
            TaskID: taskID,
            RequestID: requestID,
            AssignedTo: newValue,
            User: currentUser,
        };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/upateTaskAssignee";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            throw new Error("Failed to update task assignee");
        }
        console.log("Task Assignee Updated: ", newValue);
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating task assignee:", error);
        throw error;
    }
};

//
const updateRequestAssignee = async (requestID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            RequestID: requestID,
            AssignedTo: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/updateRequestAssignee";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updateRequestPriority = async (requestID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            RequestID: requestID,
            Priority: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/updateRequestPriority";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update request Priority");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updatePriority = async (incidentID, newValue, currentUser) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            IncidentID: incidentID,
            Priority: newValue,
            User: currentUser,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/incidentPriority";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const updateConfigItem = async (incidentID, newValue) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const payload = {
            IncidentID: incidentID,
            ConfigurationItem: newValue,
        };

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/updateCI";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Failed to update CI");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating CI:", error);
        throw error;
    }
};
//

const updateTaskNotes = async (requestID, taskID, note, userEmail) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/taskLog";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({
                RequestID: requestID,
                TaskID: taskID,
                Notes: note,
                User: userEmail,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to submit note");
        }
        console.log("Note Response: ", response);
        console.log("Note submitted successfully");
        return true;
    } catch (error) {
        console.error("Error submitting note:", error.message);
        throw error;
    }
};

const updateRequestNotes = async (requestID, note, userEmail) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/requestLog";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({
                RequestID: requestID,
                Notes: note,
                User: userEmail,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to submit note");
        }
        console.log("Note submitted successfully");
        return true;
    } catch (error) {
        console.error("Error submitting note:", error.message);
        throw error;
    }
};

const updateIncidentLog = async (incidentID, note, userEmail) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/incidentLog";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({
                IncidentID: incidentID,
                Notes: note,
                User: userEmail,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to submit note");
        }
        console.log("Note submitted successfully");
        return true;
    } catch (error) {
        console.error("Error submitting note:", error.message);
        throw error;
    }
};

/*
//
*/
const updateIncidentFileIDs = async (incidentID, fileID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/incidentAttachments";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({ IncidentID: incidentID, FileID: fileID }),
        });

        if (!response.ok) {
            throw new Error("Failed to Upload File");
        }

        const responseData = await response.json();
        console.log("File IDs Updated: ", responseData);
        return true;
    } catch (error) {
        console.error("Error updating File IDs:", error.message);
        throw error;
    }
};

const updateTaskFileIDs = async (taskID, requestID, fileIDs) => {
    try {
        const stringIDs = JSON.stringify(fileIDs);
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/taskAttachments";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({
                TaskID: taskID,
                FileIDs: stringIDs,
                RequestID: requestID,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to Upload File");
        }

        const responseData = await response.json();
        console.log("File IDs Updated: ", responseData);
        return true;
    } catch (error) {
        console.error("Error updating File IDs:", error.message);
        throw error;
    }
};

const getAttachmentIDs = async (incidentID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/getAttachmentIDs";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({ IncidentID: incidentID }),
        });

        if (!response.ok) {
            throw new Error("Failed to Get Attachment Ids");
        }

        const responseData = await response.json();
        console.log("Attachment IDs Retrieved: ", responseData);
        return responseData;
    } catch (error) {
        console.error("Error updating File IDs:", error.message);
        throw error;
    }
};

const getTaskAttachmentIDs = async (requestID, taskID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan/getTaskAttachmentIDs";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({
                TaskID: taskID,
                RequestID: requestID,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to Get Attachment Ids");
        }

        const responseData = await response.json();
        console.log("Attachment IDs Retrieved: ", responseData);
        return responseData;
    } catch (error) {
        console.error("Error updating File IDs:", error.message);
        throw error;
    }
};

const createIncident = async (payload) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/createIncident";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        const responseData = await response.json();
        console.log("Response:", responseData);
        if (response.ok) {
            return responseData;
        } else {
            throw new Error("Failed to submit Incident");
        }
    } catch (error) {
        console.error("Error submitting Incident:", error.message);
        throw error;
    }
};

const fetchServiceRequest = async (requestID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = { RequestID: requestID };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getRequest";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to fetch service request");
        }
    } catch (error) {
        console.error("Error fetching service request:", error);
        throw error;
    }
};

const updateServiceRequestStatus = async (requestID, newValue) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = { RequestID: requestID, Status: newValue };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateLifespan";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            throw new Error("Failed to update status");
        }
        return true; // Indicate success
    } catch (error) {
        console.error("Error updating status:", error);
        throw error;
    }
};

const createServiceRequest = async (payload) => {
    try {
        console.log("Submitting Service Request...", payload);
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/createServiceRequest";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        const responseData = await response.json();
        console.log("Prelim Response: ", responseData);
        if (response.ok) {
            return responseData;
        } else {
            throw new Error("Failed to Submit Service Request");
        }
    } catch (error) {
        console.error("Error Submitting Service Request:", error.message);
        throw error;
    }
};

const fetchServiceRequests = async (projectNumber = null) => {
    try {
      if (!projectNumber) {
        return { records: [] };
      }
  
      // Get auth token
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
  
      // First make the API call to get the query ID and chunk information
      const apiUrl = "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getRequests";
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      };
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({ ProjectNumber: projectNumber })
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch service requests for project ${projectNumber}`);
      }
  
      const data = await response.json();
      console.log("Data Response:", data);
      const parsedData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
  
      // Extract the query ID from the response
      const queryId = parsedData.queryId;
  
      // Now fetch all chunks from S3 using our helper function
      const chunkPromises = parsedData.chunks.map(async (chunk, index) => {
        try {
          return await fetchChunkFromS3(queryId, index + 1);
        } catch (error) {
          console.error(`Error fetching chunk ${index + 1}:`, error);
          throw error;
        }
      });
  
      // Process chunks in parallel
      const chunks = await Promise.all(chunkPromises);
      const records = chunks.flat();
      console.log(`Retrieved ${records.length} total records for project ${projectNumber}`);
      return { records };
  
    } catch (error) {
      console.error("Error in fetchServiceRequests:", error);
      throw error;
    }
  };

const fetchTask = async (requestID, taskID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = { RequestID: requestID, TaskID: taskID };
        const apiUrl = 
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getTask";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to fetch service requests");
        }
    } catch (error) {
        console.error("Error fetching service requests:", error);
        throw error;
    }
};

const fetchTasks = async (requestID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const payload = { RequestID: requestID };
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getTasks";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({ RequestID: requestID }),
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to fetch service requests");
        }
    } catch (error) {
        console.error("Error fetching service requests:", error);
        throw error;
    }
};

const fetchChanges = async () => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getChanges";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Change Data:", data.body);
            return data;
        } else {
            throw new Error("Failed to fetch Changes");
        }
    } catch (error) {
        console.error("Error fetching Changes:", error);
        throw error;
    }
};

const fetchChangeRequest = async (changeID) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getChangeRequest";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ ChangeID: changeID }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Change Data:", data);
            return data;
        } else {
            throw new Error("Failed to fetch Changes");
        }
    } catch (error) {
        console.error("Error fetching Changes:", error);
        throw error;
    }
};

const updateChangeStatus = async (changeID, newStatus, user) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl = "ADD URL HERE";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                ChangeID: changeID,
                ChangeStatus: newStatus,
                User: user,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Change Data:", data);
            return data;
        } else {
            throw new Error("Failed to fetch Changes");
        }
    } catch (error) {
        console.error("Error fetching Changes:", error);
        throw error;
    }
};

const createChangeRequest = async (payload) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/createChangeRequest";

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Change Data:", data);
            return data;
        } else {
            throw new Error("Failed to Create Change");
        }
    } catch (error) {
        console.error("Error Creating Change:", error);
        throw error;
    }
};

const checkProjectAvailability = async (projectName) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/checkProject";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ projectName: projectName }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Response: ", data);
            return data; // Assuming the API returns { available: true/false }
        } else {
            throw new Error("Failed to check project availability");
        }
    } catch (error) {
        console.error("Error checking project availability:", error);
        throw error;
    }
};

const fetchAllAssignmentGroups = async () => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getAllAGs";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "GET",
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Assignment Groups:", data.body.assignmentGroups);
            return data.body.assignmentGroups;
        } else {
            throw new Error("Failed to fetch Assignment Groups");
        }
    } catch (error) {
        console.error("Error fetching Assignment Groups:", error);
        throw error;
    }
};

const createAssignmentGroups = async (assignmentGroups) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/createAssignmentGroups";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ assignmentGroups }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Assignment Groups Created:", data);
            return data;
        } else {
            throw new Error("Failed to create Assignment Groups");
        }
    } catch (error) {
        console.error("Error creating Assignment Groups:", error);
        throw error;
    }
};

const updateAssignmentGroupMembers = async (assignmentGroup, members) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const apiUrl =
        "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateAGMembers";

    try {
        console.log(
            `Updating members for assignment group: ${assignmentGroup}`,
        );
        console.log(`Members list:`, members);

        const payload = {
            assignmentGroup,
            members,
        };

        console.log("Payload being sent:", payload);

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
                errorData.message ||
                    "Failed to update assignment group members",
            );
        }

        return await response.json();
    } catch (error) {
        console.error(
            `Error in updateAssignmentGroupMembers: ${error.message}`,
        );
        throw error;
    }
};

const updateProject = async (projectData) => {
    try {
        console.log("Project Data:", projectData);
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateProject";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const payload = {
            ProjectNumber: projectData.ProjectNumber,
            ProjectName: projectData.ProjectName,
            ProjectOwner: projectData.ProjectOwner,
            CustomerName: projectData.CustomerName,
            ProjectDescription: projectData.ProjectDescription,
            StartDate: projectData.StartDate,
            EndDate: projectData.EndDate,
            ProjectMemberCount: projectData.ProjectMemberCount,
            ServiceGroupsCount: projectData.ServiceGroupsCount,
            ServiceDefinitionsCount: projectData.ServiceDefinitionsCount,
            ProjectStatus: projectData.ProjectStatus,
        };
        console.log("Uploading: ", payload);
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to update project");
        }
    } catch (error) {
        console.error("Error updating project:", error);
        throw error;
    }
};

const fetchProjects = async () => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getProjects";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "GET",
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Projects retrieved:", data);
            return data.body.items;
        } else {
            throw new Error("Failed to fetch projects");
        }
    } catch (error) {
        console.error("Error fetching projects:", error);
        throw error;
    }
};

const fetchProjectFromS3 = async (projectNumber) => {
    try {
        const downloadResult = await downloadData({
            key: `projects/${projectNumber}.json`,
        }).result;
        console.log("Load project:", `projects/${projectNumber}.json`);
        const text = await downloadResult.body.text();
        const projectData = JSON.parse(text);

        console.log("Project data retrieved from storage:", projectData);
        return projectData;
    } catch (error) {
        console.error("Error fetching project from storage:", error);
        throw error;
    }
};

const fetchChunkFromS3 = async (queryId, chunkNumber) => {
    try {
      // Format chunk number to match the Lambda's padding (5 digits)
      const paddedChunkNumber = String(chunkNumber).padStart(5, '0');
      const chunkKey = `dynamodb-large-queries/${queryId}/chunks/chunk_${paddedChunkNumber}.json`;
      
      console.log("Load chunk:", chunkKey);
      
      const downloadResult = await downloadData({
        key: chunkKey,
        bucket: 'ciservicedesk' // Matching the Lambda's S3_BUCKET
      }).result;
      
      const text = await downloadResult.body.text();
      const chunkData = JSON.parse(text);
      
      console.log("Chunk data retrieved from storage:", chunkData);
      return chunkData;
      
    } catch (error) {
      console.error(`Error fetching chunk ${chunkNumber} for query ${queryId}:`, error);
      throw error;
    }
  };

const getUserTasks = async (email) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getUserTasks";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("User tasks retrieved:", data);
            return data;
        } else {
            throw new Error("Failed to fetch user tasks");
        }
    } catch (error) {
        console.error("Error fetching user tasks:", error);
        throw error;
    }
};

const getUserProjects = async (email) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getUserProjects";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("User Projects retrieved:", data);
            return data;
        } else {
            throw new Error("Failed to fetch user Projects");
        }
    } catch (error) {
        console.error("Error fetching user Projects:", error);
        throw error;
    }
};

const updateUser = async (userData) => {
    try {
        const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};
        const licenseKey = process.env.REACT_APP_LICENSE_KEY;

        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/updateUser";
        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const payload = {
            EmailAddress: userData.EmailAddress,
            LicenseKey: licenseKey,
            AccessRights: userData.AccessRights, // Send AccessRights as an array
        };
        console.log("Update User Payload:", payload);

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });
        console.log("RESPONSE:", response);
        if (response.ok) {
            const responseData = await response.json();
            console.log("User updated successfully:", responseData);
            return responseData;
        } else {
            throw new Error("Failed to update user", response);
        }
    } catch (error) {
        console.error("Error updating user:", error.message);

        throw error;
    }
};

const getAssignedIncidents = async (userEmail) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getAssignedIncidents";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const payload = {
            AssignedTo: userEmail,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Assigned incidents retrieved:", data);
            return data;
        } else {
            throw new Error("Failed to fetch assigned incidents");
        }
    } catch (error) {
        console.error("Error fetching assigned incidents:", error);
        throw error;
    }
};

const getAssignedTicketsCounts = async (userEmail) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const apiUrl = "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/COUNT_ASSIGNED_TICKETS";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const payload = {
            AssignedTo: userEmail,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Assigned tickets count retrieved:", data);
            return data;
        } else {
            throw new Error("Failed to fetch assigned tickets count");
        }
    } catch (error) {
        console.error("Error fetching assigned tickets count:", error);
        throw error;
    }
};

const getAssignedServices = async (userEmail) => {
    try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const apiUrl =
            "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/getAssignedServices";

        const headers = {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        };

        const payload = {
            AssignedTo: userEmail,
        };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Assigned Services retrieved:", data);
            return data;
        } else {
            throw new Error("Failed to fetch assigned Services");
        }
    } catch (error) {
        console.error("Error fetching assigned incidents:", error);
        throw error;
    }
};

// Export the functions
export {
    getUserProjects,
    getAssignedServices,
    getAssignedIncidents,
    updateUser,
    getUserTasks,
    fetchProjectFromS3,
    fetchProjects,
    updateProject,
    updateAssignmentGroupMembers,
    createAssignmentGroups,
    fetchAllAssignmentGroups,
    ensureUserExists,
    fetchUsers,
    fetchIncident,
    updateStatus,
    updateIncidentLog,
    createIncident,
    fetchIncidents,
    fetchServiceRequest,
    updateServiceRequestStatus,
    createServiceRequest,
    fetchServiceRequests,
    fetchTask,
    fetchTasks,
    fetchChanges,
    fetchChangeRequest,
    updateChangeStatus,
    createChangeRequest,
    updatePriority,
    updateAssignee,
    updateIncidentFileIDs,
    getAttachmentIDs,
    updateConfigItem,
    updateRequestNotes,
    updateRequestPriority,
    updateRequestAssignee,
    updateTaskFileIDs,
    getTaskAttachmentIDs,
    updateTaskNotes,
    updateTaskStatus,
    checkProjectAvailability,
    updateTaskAssignee,
    getAssignedTicketsCounts
};
