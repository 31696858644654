import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Autocomplete,
    Chip,
    Box,
    Typography,
    IconButton,
    CircularProgress,
    Alert
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { 
    connectWebSocket, 
    disconnectWebSocket, 
    setWebSocketStatusChangeCallback, 
    setTechniciansUpdateCallback, 
    ws, 
    requestTechnicians 
} from './services';
import ProjectConnectionStatus from './ProjectConnectionStatus';

const TechnicianCalendar = ({ 
    technician = null,  // Full technician object when launched from card
    isStandalone = true, // Whether this is standalone or launched from card
    onTechnicianUpdate = null // Callback for updating technician in parent
}) => {
    // Initialize events from technician if provided
    const [events, setEvents] = useState(() => {
        if (!isStandalone && technician) {
            return technician.Scheduled?.map(schedule => ({
                title: `${technician.Name} - ${schedule.projectName || 'Project'}`,
                start: dayjs(schedule.projectDates.startDate).format('YYYY-MM-DD'),
                end: dayjs(schedule.projectDates.endDate).add(1, 'day').format('YYYY-MM-DD'),
                color: '#58c1fb',
                techId: technician.Email,
                techName: technician.Name,
                projectName: schedule.projectName,
                projectNumber: schedule.projectNumber,
                editable: true
            })) || [];
        }
        return [];
    });

    const [filteredEvents, setFilteredEvents] = useState(events);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // Initialize technician filter if provided
    const [selectedTechnicians, setSelectedTechnicians] = useState(() => {
        if (!isStandalone && technician) {
            return [{
                id: technician.Email,
                name: technician.Name
            }];
        }
        return [];
    });

    // Initialize available technicians
    const [availableTechnicians, setAvailableTechnicians] = useState(() => {
        if (!isStandalone && technician) {
            return [{
                id: technician.Email,
                name: technician.Name
            }];
        }
        return [];
    });

    // Standalone mode states
    const [connectionStatus, setConnectionStatus] = useState(isStandalone ? 'disconnected' : 'not-required');
    const [isConnecting, setIsConnecting] = useState(false);
    const [isLoadingTechnicians, setIsLoadingTechnicians] = useState(isStandalone);
    const [techniciansError, setTechniciansError] = useState(null);
    const [techniciansCount, setTechniciansCount] = useState(0);
    const [techniciansData, setTechniciansData] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);

    // Refs for colors
    const colorIndex = useRef(0);
    const colors = [
        '#58c1fb', '#2b9ddb', '#3686b5', '#38759c', '#376887',
        '#355d78', '#32546b', '#2f4c60', '#2d4556', '#293e4d'
    ];
    // Utility functions
    const getNextColor = () => {
        const color = colors[colorIndex.current];
        colorIndex.current = (colorIndex.current + 1) % colors.length;
        return color;
    };

    // Standalone mode utility functions
    const handleDisconnect = async () => {
        if (!isStandalone) return;
        try {
            disconnectWebSocket();
        } catch (error) {
            console.error('Failed to disconnect:', error);
        }
    };

    const handleReconnect = async () => {
        if (!isStandalone) return;
        setIsConnecting(true);
        try {
            await connectWebSocket();
        } catch (error) {
            console.error('Failed to reconnect:', error);
        } finally {
            setIsConnecting(false);
        }
    };

    // Event handlers
    const handleEventClick = (clickInfo) => {
        setSelectedEvent({
            ...clickInfo.event.toPlainObject(),
            extendedProps: clickInfo.event.extendedProps
        });
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedEvent(null);
    };

    const handleDeleteEvent = () => {
        if (!selectedEvent) return;

        if (!isStandalone && technician) {
            // Handle delete in card mode
            const updatedSchedule = technician.Scheduled.filter(
                schedule => schedule.projectNumber !== selectedEvent.extendedProps.projectNumber
            );

            const updatedTechnician = {
                ...technician,
                Scheduled: updatedSchedule
            };

            // Update events state
            const updatedEvents = events.filter(
                event => event.projectNumber !== selectedEvent.extendedProps.projectNumber
            );
            setEvents(updatedEvents);
            setFilteredEvents(updatedEvents);

            // Notify parent component
            if (onTechnicianUpdate) {
                onTechnicianUpdate(updatedTechnician);
            }
        } else if (ws && ws.readyState === WebSocket.OPEN) {
            // Handle delete in standalone mode
            const techToUpdate = techniciansData.find(
                tech => tech.Email === selectedEvent.extendedProps.techId
            );

            if (techToUpdate) {
                const updatedSchedule = techToUpdate.Scheduled.filter(
                    schedule => schedule.projectNumber !== selectedEvent.extendedProps.projectNumber
                );

                const updatedTechnician = {
                    ...techToUpdate,
                    Scheduled: updatedSchedule
                };

                ws.send(JSON.stringify({
                    action: 'updatetech',
                    data: {
                        technician: updatedTechnician
                    }
                }));

                setTimeout(requestTechnicians, 500);
            }
        }

        handleDialogClose();
    };

    const handleEventUpdate = () => {
        if (!selectedEvent) return;

        if (!isStandalone && technician) {
            // Handle update in card mode
            const updatedSchedule = technician.Scheduled.map(schedule => {
                if (schedule.projectNumber === selectedEvent.extendedProps.projectNumber) {
                    return {
                        ...schedule,
                        projectDates: {
                            startDate: dayjs(selectedEvent.start).format('YYYY-MM-DD'),
                            endDate: dayjs(selectedEvent.end).subtract(1, 'day').format('YYYY-MM-DD')
                        }
                    };
                }
                return schedule;
            });

            const updatedTechnician = {
                ...technician,
                Scheduled: updatedSchedule
            };

            // Update events state
            const updatedEvents = events.map(event => {
                if (event.projectNumber === selectedEvent.extendedProps.projectNumber) {
                    return {
                        ...event,
                        start: dayjs(selectedEvent.start).format('YYYY-MM-DD'),
                        end: dayjs(selectedEvent.end).format('YYYY-MM-DD')
                    };
                }
                return event;
            });
            setEvents(updatedEvents);
            setFilteredEvents(updatedEvents);

            // Notify parent component
            if (onTechnicianUpdate) {
                onTechnicianUpdate(updatedTechnician);
            }
        } else if (ws && ws.readyState === WebSocket.OPEN) {
            // Handle update in standalone mode
            const techToUpdate = techniciansData.find(
                tech => tech.Email === selectedEvent.extendedProps.techId
            );

            if (techToUpdate) {
                const updatedSchedule = techToUpdate.Scheduled.map(schedule => {
                    if (schedule.projectNumber === selectedEvent.extendedProps.projectNumber) {
                        return {
                            ...schedule,
                            projectDates: {
                                startDate: dayjs(selectedEvent.start).format('YYYY-MM-DD'),
                                endDate: dayjs(selectedEvent.end).subtract(1, 'day').format('YYYY-MM-DD')
                            }
                        };
                    }
                    return schedule;
                });

                const updatedTechnician = {
                    ...techToUpdate,
                    Scheduled: updatedSchedule
                };

                ws.send(JSON.stringify({
                    action: 'updatetech',
                    data: {
                        technician: updatedTechnician
                    }
                }));

                setTimeout(requestTechnicians, 500);
            }
        }

        handleDialogClose();
    };

    const handleTechnicianFilterChange = (event, newValue) => {
        setSelectedTechnicians(newValue);
        if (newValue.length === 0) {
            setFilteredEvents(events);
        } else {
            const filtered = events.filter(event =>
                newValue.some(tech => tech.id === event.techId)
            );
            setFilteredEvents(filtered);
        }
    };

    const handleClearFilter = () => {
        setSelectedTechnicians([]);
        setFilteredEvents(events);
    };

    // Effect for WebSocket connection and data handling - only for standalone mode
    useEffect(() => {
        if (!isStandalone) return; // Skip WebSocket setup if in card mode

        let mounted = true;

        setWebSocketStatusChangeCallback((status) => {
            if (mounted) {
                setConnectionStatus(status);
                if (status === 'connected') {
                    setIsConnecting(false);
                }
            }
        });

        setTechniciansUpdateCallback((techData) => {
            if (!mounted) return;

            if (techData.action === 'technician_updated') {
                const updatedTech = techData.data.technician;
                setTechniciansData(prev => prev.map(tech =>
                    tech.Email === updatedTech.Email ? updatedTech : tech
                ));
                processTechnicianData([...techniciansData], updatedTech.Email);
            } else {
                setIsLoadingTechnicians(techData.isLoading);
                if (techData.Technicians) {
                    setTechniciansCount(techData.Technicians.length);
                    processTechnicianData(techData.Technicians);
                }
            }
        });

        const initializeWebSocket = async () => {
            setIsConnecting(true);
            try {
                await connectWebSocket();
            } catch (error) {
                setTechniciansError('Failed to establish WebSocket connection');
            } finally {
                if (mounted) {
                    setIsConnecting(false);
                }
            }
        };

        initializeWebSocket();

        return () => {
            mounted = false;
            disconnectWebSocket();
            setWebSocketStatusChangeCallback(null);
            setTechniciansUpdateCallback(null);
        };
    }, [isStandalone]);

    // Process technician data for standalone mode
    const processTechnicianData = (technicians, updatedTechEmail = null) => {
        if (!isStandalone) return; // Skip processing if in card mode

        setTechniciansData(technicians);
        setIsFiltering(true);

        const techsWithSchedule = technicians.filter(tech =>
            tech.Scheduled && tech.Scheduled.length > 0
        );

        setAvailableTechnicians(techsWithSchedule.map(tech => ({
            id: tech.Email,
            name: tech.Name
        })));

        let currentEvents = [...events];
        if (updatedTechEmail) {
            currentEvents = currentEvents.filter(event => event.techId !== updatedTechEmail);
        } else {
            colorIndex.current = 0;
            currentEvents = [];
        }

        const calendarEvents = techsWithSchedule.flatMap(tech => {
            if (updatedTechEmail && tech.Email !== updatedTechEmail) {
                return [];
            }

            return tech.Scheduled.map(schedule => ({
                title: `${tech.Name} - ${schedule.projectName || 'Project'}`,
                start: dayjs(schedule.projectDates.startDate).format('YYYY-MM-DD'),
                end: dayjs(schedule.projectDates.endDate).add(1, 'day').format('YYYY-MM-DD'),
                color: updatedTechEmail ? getNextColor() : getNextColor(),
                techId: tech.Email,
                techName: tech.Name,
                projectName: schedule.projectName,
                projectNumber: schedule.projectNumber,
                editable: true
            }));
        });

        const newEvents = updatedTechEmail
            ? [...currentEvents, ...calendarEvents]
            : calendarEvents;

        setEvents(newEvents);
        
        if (selectedTechnicians.length > 0) {
            const filtered = newEvents.filter(event =>
                selectedTechnicians.some(tech => tech.id === event.techId)
            );
            setFilteredEvents(filtered);
        } else {
            setFilteredEvents(newEvents);
        }

        setIsFiltering(false);
    };

    // Effect to update filtered events when selected technicians change
    useEffect(() => {
        if (selectedTechnicians.length === 0) {
            setFilteredEvents(events);
        } else {
            const filtered = events.filter(event =>
                selectedTechnicians.some(tech => tech.id === event.techId)
            );
            setFilteredEvents(filtered);
        }
    }, [selectedTechnicians, events]);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                {/* Only show filter section in standalone mode */}
                {isStandalone && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Autocomplete
                                multiple
                                id="technician-filter"
                                options={availableTechnicians}
                                value={selectedTechnicians}
                                onChange={handleTechnicianFilterChange}
                                getOptionLabel={(option) => `${option.name} (${option.id})`}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Filter Technicians"
                                        placeholder={selectedTechnicians.length ? "" : "Select technicians"}
                                        style={{ width: 400 }}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option.id}
                                            variant="outlined"
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                            {selectedTechnicians.length > 0 && (
                                <IconButton onClick={handleClearFilter} size="small">
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Box>
                        <ProjectConnectionStatus
                            connectionStatus={connectionStatus}
                            techniciansCount={techniciansCount}
                            isLoadingTechnicians={isLoadingTechnicians}
                            techniciansError={techniciansError}
                            isConnecting={isConnecting}
                            onDisconnect={handleDisconnect}
                            onReconnect={handleReconnect}
                        />
                    </Box>
                )}

                {/* Show loading state when filtering or initializing in standalone mode */}
                {isStandalone && (isFiltering || isLoadingTechnicians) ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : (
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek'
                        }}
                        events={filteredEvents}
                        eventClick={handleEventClick}
                        height="100%"
                        eventContent={(eventInfo) => ({
                            html: `
                                <div class="fc-content">
                                    <div class="fc-title" style="font-weight: bold;">
                                        ${eventInfo.event.extendedProps.techName}
                                    </div>
                                    <div class="fc-description" style="font-size: 0.9em;">
                                        ${eventInfo.event.extendedProps.projectName || 'Project'}
                                    </div>
                                </div>
                            `
                        })}
                    />
                )}

                <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Edit Schedule</Typography>
                            <IconButton
                                onClick={handleDeleteEvent}
                                color="error"
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {selectedEvent && (
                            <>
                                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                                    {selectedEvent.extendedProps.techName} - {selectedEvent.extendedProps.projectName}
                                </Typography>
                                <DatePicker
                                    label="Start Date"
                                    value={dayjs(selectedEvent.start)}
                                    onChange={(date) => setSelectedEvent(prev => ({
                                        ...prev,
                                        start: date.format('YYYY-MM-DD')
                                    }))}
                                    sx={{ width: '100%', mb: 2 }}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={dayjs(selectedEvent.end).subtract(1, 'day')}
                                    onChange={(date) => setSelectedEvent(prev => ({
                                        ...prev,
                                        end: date.add(1, 'day').format('YYYY-MM-DD')
                                    }))}
                                    sx={{ width: '100%' }}
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleEventUpdate} variant="contained" color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </LocalizationProvider>
    );
};

TechnicianCalendar.propTypes = {
    technician: PropTypes.shape({
        Email: PropTypes.string,
        Name: PropTypes.string,
        Scheduled: PropTypes.arrayOf(PropTypes.shape({
            projectNumber: PropTypes.string,
            projectName: PropTypes.string,
            projectDates: PropTypes.shape({
                startDate: PropTypes.string,
                endDate: PropTypes.string
            })
        }))
    }),
    isStandalone: PropTypes.bool,
    onTechnicianUpdate: PropTypes.func
};

export default TechnicianCalendar;