import { downloadData, getUrl, uploadData } from "aws-amplify/storage";
import AWS from "aws-sdk";
import servicesData from "../../Data/servicesDatabase.json";
export const uploadToS3 = async (data) => {
    const uuid = data.ProjectNumber;
    const fileName = `projects/${uuid}.json`;

    try {
        console.log("Uploading file to S3:", fileName);
        const fileContent = JSON.stringify(data);
        const file = new Blob([fileContent], { type: "application/json" });
        const result = await uploadData({
            key: fileName,
            data: file,
            options: {
                bucket: "CiServiceDesk",
                contentType: "application/json",
            },
        }).result;

        console.log(`File Uploaded Successfully:,`, result);
        return { fileName, uuid };
    } catch (error) {
        console.error("Error uploading file to S3:", error);
        throw error;
    }
};

export const createServicesPayload = (
    projectData,
    completeServiceDefinitions,
) => {
    const currentDate = new Date().toISOString();

    return completeServiceDefinitions.flatMap((definition) => {
        const serviceType = servicesData.response[0].Network.concat(
            servicesData.response[0]["Physical Security"],
            servicesData.response[0]["Hardware Installations"],
        ).find((service) => service["Service Type"] === definition.service);

        if (!serviceType) {
            console.error(`Service type not found: ${definition.service}`);
            return [];
        }

        const serviceGroupName = `AG-${projectData.projectName.replace(
            /\s+/g,
            "",
        )}-${definition.service.replace(/\s+/g, "")}`;

        return Array(parseInt(definition.count, 10))
            .fill()
            .map(() => ({
                ProjectName: projectData.projectName,
                ProjectNumber: projectData.projectUuid,
                DueDate: projectData.endDate
                    ? projectData.endDate.toISOString()
                    : null,
                CreationTime: currentDate,
                RequestType: definition.service,
                Priority: "Priority 5 - Planning",
                Impact: "5",
                AssignedTo: projectData.projectOwner,
                RequestDescription: serviceType.Description,
                RequesterName: projectData.projectOwner,
                RequesterEmail: projectData.projectOwner,
                RequesterPhoneNumber: "",
                RelatedTickets: [],
                ServiceStatus: "New",
                Organization: projectData.customerName,
                Location: definition.building,
                Floor: definition.floor,
                AssignedGroup: "AG-PROJECT-MANAGEMENT-SVC",
                Note: `Service created as part of project ${projectData.projectName}`,
                Tasks: serviceType.Tasks.filter((task) => {
                    // Check if there's a matching group in the current project service groups
                    return definition.groups.some(
                        (group) => group.task === task.name,
                    );
                }).map((task) => {
                    const matchingGroup = definition.groups.find(
                        (group) => group.task === task.name,
                    );
                    return {
                        name: task.name,
                        description: task.description,
                        assignmentGroup: matchingGroup
                            ? `${serviceGroupName}-${task.name.replace(/\s+/g, "")}`
                            : "",
                        members: matchingGroup ? matchingGroup.members : [],
                        level: task.level,
                        status: task.status,
                        projectNumber: projectData.projectUuid,
                        projectName: projectData.projectName,
                        assignedTo:
                            matchingGroup && matchingGroup.members.length > 0
                                ? matchingGroup.members[0].EmailAddress
                                : "",
                        taskMembers: JSON.stringify(
                            matchingGroup ? matchingGroup.members : [],
                        ),
                        attachmentIDs: projectData.projectFiles,
                    };
                }),
                ServiceID: serviceType.serviceID,
                Vendor: serviceType.Vendor,
                Manufacturer: serviceType.Manufacturer,
                SKU: serviceType.SKU,
                ListPrice: serviceType["List Price"],
                MiscExpenses: serviceType["Misc Expenses"],
                TimePer: serviceType["Time Per"],
            }));
    });
};
