import React, { useState, useEffect } from 'react';
import { downloadData, uploadData } from "aws-amplify/storage";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';

const BuildingManager = () => {
  const [companies, setCompanies] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [buildingToDelete, setBuildingToDelete] = useState(null);
  const [currentBuilding, setCurrentBuilding] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [newCompanyName, setNewCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const defaultBuilding = {
    'Building Number': '',
    'Building Name': '',
    'Zone': '',
    'Building Coordinator': '',
    'Email': '',
    'Phone': '',
    'Address': '',
    'Floors': ''
  };

  const fetchLocationsData = async () => {
    console.log('fetchLocationsData: Starting fetch operation');
    try {
      const downloadResult = await downloadData({
        key: 'databases/locations.json'
      }).result;
      console.log('fetchLocationsData: Download successful');
      
      const text = await downloadResult.body.text();
      console.log('fetchLocationsData: Text content received, length:', text.length);
      
      const parsed = JSON.parse(text);
      console.log('fetchLocationsData: Successfully parsed data', {
        companyCount: Object.keys(parsed).length,
        companies: Object.keys(parsed)
      });
      return parsed;
    } catch (error) {
      console.error('fetchLocationsData: Error occurred:', error);
      throw error;
    }
  };

  const saveLocationsData = async (locationsData) => {
    console.log('saveLocationsData: Starting save operation', {
      dataSize: JSON.stringify(locationsData).length,
      companies: Object.keys(locationsData)
    });
    
    try {
      await uploadData({
        key: 'databases/locations.json',
        data: JSON.stringify(locationsData, null, 2),
        options: {
          contentType: 'application/json'
        }
      });
      console.log('saveLocationsData: Save successful');
    } catch (error) {
      console.error('saveLocationsData: Error occurred:', error);
      throw error;
    }
  };

  const loadLocationsData = async () => {
    console.log('loadLocationsData: Starting data load');
    setIsLoading(true);
    
    try {
      const data = await fetchLocationsData();
      console.log('loadLocationsData: Data loaded successfully', {
        companies: Object.keys(data),
        totalCompanies: Object.keys(data).length
      });
      setCompanies(data);
    } catch (error) {
      console.error('loadLocationsData: Error loading data:', error);
      showSnackbar('Error loading locations data', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('BuildingManager: Component mounted');
    loadLocationsData();
  }, []);

  useEffect(() => {
    // Reset selected building when company changes
    setSelectedBuilding(null);
  }, [selectedCompany]);

  const handleCompanyChange = (event, newValue) => {
    console.log('handleCompanyChange: Company selection changed', {
      previous: selectedCompany,
      new: newValue
    });
    setSelectedCompany(newValue);
  };

  const handleBuildingSelect = (event, building) => {
    console.log('handleBuildingSelect: Building selection changed', { building });
    setSelectedBuilding(building);
    if (building) {
      const buildingIndex = companies[selectedCompany].BUILDINGS.findIndex(
        b => b['Building Name'] === building['Building Name']
      );
      setCurrentBuilding(buildingIndex);
      setOpenDialog(true);
    }
  };

  const handleAddCompany = async () => {
    console.log('handleAddCompany: Attempting to add company:', newCompanyName);
    
    if (!newCompanyName.trim()) {
      console.log('handleAddCompany: Empty company name, aborting');
      return;
    }

    try {
      const updatedCompanies = {
        ...companies,
        [newCompanyName]: {
          BUILDINGS: []
        }
      };

      console.log('handleAddCompany: Saving updated companies', {
        companyName: newCompanyName,
        totalCompanies: Object.keys(updatedCompanies).length
      });

      await saveLocationsData(updatedCompanies);
      setCompanies(updatedCompanies);
      setSelectedCompany(newCompanyName);
      setOpenCompanyDialog(false);
      showSnackbar('Company added successfully', 'success');
    } catch (error) {
      console.error('handleAddCompany: Error adding company:', error);
      showSnackbar('Error adding company', 'error');
    }
    setNewCompanyName('');
  };

  const handleSaveBuilding = async (buildingData) => {
    console.log('handleSaveBuilding: Starting save operation', {
      isNew: currentBuilding === null,
      buildingData,
      company: selectedCompany
    });

    try {
      const updatedCompanies = { ...companies };
      const buildings = [...(updatedCompanies[selectedCompany].BUILDINGS || [])];
      
      if (currentBuilding === null) {
        console.log('handleSaveBuilding: Adding new building');
        buildingData['Building Number'] = parseInt(buildingData['Building Number']);
        buildingData['Floors'] = parseInt(buildingData['Floors']);
        buildings.push(buildingData);
      } else {
        console.log('handleSaveBuilding: Updating existing building', {
          index: currentBuilding
        });
        buildingData['Building Number'] = parseInt(buildingData['Building Number']);
        buildingData['Floors'] = parseInt(buildingData['Floors']);
        buildings[currentBuilding] = buildingData;
      }

      updatedCompanies[selectedCompany].BUILDINGS = buildings;
      
      console.log('handleSaveBuilding: Saving updated data', {
        company: selectedCompany,
        buildingCount: buildings.length
      });

      await saveLocationsData(updatedCompanies);
      setCompanies(updatedCompanies);
      setOpenDialog(false);
      setSelectedBuilding(null);
      showSnackbar('Building saved successfully', 'success');
    } catch (error) {
      console.error('handleSaveBuilding: Error saving building:', error);
      showSnackbar('Error saving building data', 'error');
    }
  };

  const handleDeleteBuilding = async () => {
    if (!buildingToDelete && buildingToDelete !== 0) {
      console.log('handleDeleteBuilding: No building selected for deletion');
      return;
    }

    try {
      const updatedCompanies = { ...companies };
      const buildings = updatedCompanies[selectedCompany].BUILDINGS.filter((_, i) => i !== buildingToDelete);
      updatedCompanies[selectedCompany].BUILDINGS = buildings;

      console.log('handleDeleteBuilding: Saving updated data', {
        company: selectedCompany,
        remainingBuildings: buildings.length
      });

      await saveLocationsData(updatedCompanies);
      setCompanies(updatedCompanies);
      setSelectedBuilding(null);
      setOpenDeleteDialog(false);
      setBuildingToDelete(null);
      showSnackbar('Building deleted successfully', 'success');
    } catch (error) {
      console.error('handleDeleteBuilding: Error deleting building:', error);
      showSnackbar('Error deleting building', 'error');
      setOpenDeleteDialog(false);
      setBuildingToDelete(null);
    }
  };

  const showSnackbar = (message, severity) => {
    console.log('showSnackbar: Showing notification', { message, severity });
    setSnackbar({ open: true, message, severity });
  };

  const BuildingDialog = ({ open, onClose, building, onSave }) => {
    console.log('BuildingDialog: Rendered', {
      isOpen: open,
      isEditing: !!building,
      buildingData: building
    });

    const [formData, setFormData] = useState(building || defaultBuilding);

    useEffect(() => {
      console.log('BuildingDialog: Form data updated', {
        isNewBuilding: !building,
        formData: building || defaultBuilding
      });
      setFormData(building || defaultBuilding);
    }, [building]);

    const handleChange = (field) => (event) => {
      console.log('BuildingDialog: Field changed', {
        field,
        value: event.target.value
      });
      setFormData({
        ...formData,
        [field]: event.target.value
      });
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {building ? 'Edit Building' : 'Add New Building'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'grid', gap: 2, pt: 2 }}>
            <TextField
              label="Building Number"
              value={formData['Building Number']}
              onChange={handleChange('Building Number')}
              type="number"
              fullWidth
            />
            <TextField
              label="Building Name"
              value={formData['Building Name']}
              onChange={handleChange('Building Name')}
              fullWidth
            />
            <TextField
              label="Zone"
              value={formData['Zone']}
              onChange={handleChange('Zone')}
              fullWidth
            />
            <TextField
              label="Building Coordinator"
              value={formData['Building Coordinator']}
              onChange={handleChange('Building Coordinator')}
              fullWidth
            />
            <TextField
              label="Email"
              value={formData['Email']}
              onChange={handleChange('Email')}
              type="email"
              fullWidth
            />
            <TextField
              label="Phone"
              value={formData['Phone']}
              onChange={handleChange('Phone')}
              fullWidth
            />
            <TextField
              label="Address"
              value={formData['Address']}
              onChange={handleChange('Address')}
              fullWidth
              multiline
              rows={2}
            />
            <TextField
              label="Floors"
              value={formData['Floors']}
              onChange={handleChange('Floors')}
              type="number"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            onClick={() => {
              console.log('BuildingDialog: Save clicked', { formData });
              onSave(formData);
            }} 
            variant="contained" 
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant="h5">Building Locations Manager</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                console.log('Main: Add Company button clicked');
                setOpenCompanyDialog(true);
              }}
            >
              Add Company
            </Button>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Autocomplete
              value={selectedCompany}
              onChange={handleCompanyChange}
              options={Object.keys(companies)}
              renderInput={(params) => (
                <TextField {...params} label="Search Company" variant="outlined" />
              )}
              fullWidth
            />
          </Box>

          {selectedCompany && (
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                value={selectedBuilding}
                onChange={handleBuildingSelect}
                options={companies[selectedCompany]?.BUILDINGS || []}
                getOptionLabel={(option) => option['Building Name'] || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Search Building" variant="outlined" />
                )}
                fullWidth
              />
            </Box>
          )}

          {selectedCompany && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => {
                    console.log('Main: Add Building button clicked');
                    setCurrentBuilding(null);
                    setOpenDialog(true);
                  }}
                >
                  Add Building
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Building Number</TableCell>
                      <TableCell>Building Name</TableCell>
                      <TableCell>Zone</TableCell>
                      <TableCell>Coordinator</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Floors</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies[selectedCompany]?.BUILDINGS?.map((building, index) => (
                      <TableRow key={index}>
                        <TableCell>{building['Building Number']}</TableCell>
                        <TableCell>{building['Building Name']}</TableCell>
                        <TableCell>{building['Zone']}</TableCell>
                        <TableCell>{building['Building Coordinator']}</TableCell>
                        <TableCell>
                          {building['Email']}<br />
                          {building['Phone']}
                        </TableCell>
                        <TableCell>{building['Address']}</TableCell>
                        <TableCell>{building['Floors']}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              console.log('Main: Edit Building clicked', { index });
                              setCurrentBuilding(index);
                              setSelectedBuilding(building);
                              setOpenDialog(true);
                            }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton 
                              onClick={() => {
                                console.log('Main: Delete Building clicked', { index });
                                setBuildingToDelete(index);
                                setOpenDeleteDialog(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
  
            <BuildingDialog
              open={openDialog}
              onClose={() => {
                console.log('Main: Building dialog closed');
                setOpenDialog(false);
              }}
              building={currentBuilding !== null ? companies[selectedCompany]?.BUILDINGS[currentBuilding] : null}
              onSave={handleSaveBuilding}
            />
  
            <Dialog 
              open={openCompanyDialog} 
              onClose={() => {
                console.log('Main: Company dialog closed');
                setOpenCompanyDialog(false);
                setNewCompanyName('');
              }}
            >
              <DialogTitle>Add New Company</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Company Name"
                  fullWidth
                  value={newCompanyName}
                  onChange={(e) => {
                    console.log('Main: Company name input changed', { value: e.target.value });
                    setNewCompanyName(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  console.log('Main: Company dialog cancelled');
                  setOpenCompanyDialog(false);
                  setNewCompanyName('');
                }}>
                  Cancel
                </Button>
                <Button onClick={handleAddCompany} variant="contained" color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
  
            {/* Delete Confirmation Dialog */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => {
                setOpenDeleteDialog(false);
                setBuildingToDelete(null);
              }}
            >
              <DialogTitle>Delete Building</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this? This cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={() => {
                    setOpenDeleteDialog(false);
                    setBuildingToDelete(null);
                  }}
                >
                  No
                </Button>
                <Button 
                  onClick={handleDeleteBuilding} 
                  variant="contained" 
                  color="error"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
  
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => {
                console.log('Main: Snackbar closed');
                setSnackbar({ ...snackbar, open: false });
              }}
            >
              <Alert 
                severity={snackbar.severity} 
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </>
        )}
      </Box>
    );
  };
  
  export default BuildingManager;