import React, { useRef } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { updateIncidentFileIDs } from "../../../ApiServices/ApiServices";
import { uploadData } from "aws-amplify/storage";

const UploadButton = ({ incidentID }) => {
    const fileInputRef = useRef(null);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadFileToS3(file);
        }
    };

    const uploadFileToS3 = async (file) => {
        const uuid = uuidv4();
        const fileName = `${uuid}.${file.name.split(".").pop()}`;
        console.log("IncID:", incidentID);
        console.log("UUID: ", uuid);

        await updateIncidentFileIDs(incidentID, uuid);

        try {
            const result = await uploadData({
                key: fileName,
                data: file,
                options: {
                    bucket: "CiServiceDesk",
                },
            }).result;
            console.log("File uploaded successfully:", result);
            alert("Upload successful!");

            window.location.reload();
        } catch (err) {
            console.error("Error uploading file to S3:", err);
            alert("Upload failed. Please try again.");
        }
    };
    return (
        <div>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />
            <button onClick={() => fileInputRef.current.click()}>
                Choose Photo
            </button>
        </div>
    );
};

export default UploadButton;
