import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
  Paper,
  LinearProgress,
  Card,
  CardContent,
  MenuItem
} from '@mui/material';
import { BarChart, Bar, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { PieChart } from '@mui/x-charts/PieChart';
import statusData from "../Data/srStatus.json";
import { fetchProjects, fetchServiceRequests } from "../../ApiServices/ApiServices";

const StatusDonutChart = ({ value, title, data }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>{title}</Typography>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <PieChart
              series={[
                {
                  data,
                  innerRadius: 80,
                  outerRadius: 120,
                  cornerRadius: 4,
                  paddingAngle: 2,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 10, additionalRadius: -30 },
                },
              ]}
              height={300}
              width={300}
              margin={{ right: 5, left: 5, top: 5, bottom: 5 }}
              legend={{ hidden: true }}
            />
          </Box>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Typography variant="h3" color="text.primary">
              {`${Math.round(value)}%`}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Completed
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
          {data.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: item.color,
                  mr: 1,
                }}
              />
              <Typography variant="body2">
                {item.label}: {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const RequestTypeBarChart = ({ data, theme }) => (
  <Paper 
    sx={{ 
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '600px',
      height: 'auto'
    }}
  >
    <Typography variant="h6" gutterBottom align="center">
      Request Type Distribution
    </Typography>
    <Box 
      sx={{ 
        width: '100%', 
        height: data.length * 50 + 100, 
        minHeight: 400, 
        maxHeight: 800,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ResponsiveContainer width="80%" height="100%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 20, right: 100, left: 100, bottom: 20 }}
        >
          <XAxis 
            type="number"
            axisLine={true}
            tickLine={true}
          />
          <YAxis 
            type="category" 
            dataKey="name" 
            width={240}
            tick={{ 
              fill: theme.palette.text.primary,
              fontSize: 12,
            }}
            tickFormatter={(value, index) => {
              const entry = data[index];
              return `${value} (${entry.value})`;
            }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
            }}
            formatter={(value) => [`${value} services`]}
          />
          <Bar dataKey="value" barSize={30}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  </Paper>
);

const Reporting = () => {
  const theme = useTheme();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [locations, setLocations] = useState([]);
  const [floors, setFloors] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedFloor, setSelectedFloor] = useState('');

  const typeColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.secondary.light,
    theme.palette.primary.light,
    theme.palette.success.light,
    theme.palette.info.light,
  ];

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectsData = await fetchProjects();
        setProjects(projectsData || []);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjectData();
  }, []);

  useEffect(() => {
    if (services.length > 0) {
      const uniqueLocations = [...new Set(services.map(service => service.Location))].filter(Boolean);
      setLocations(uniqueLocations);
      setSelectedLocation('');
      setSelectedFloor('');
      setFilteredServices(services);
    } else {
      setLocations([]);
      setFloors([]);
      setFilteredServices([]);
    }
  }, [services]);

  useEffect(() => {
    if (selectedLocation) {
      const locationServices = services.filter(service => service.Location === selectedLocation);
      const uniqueFloors = [...new Set(locationServices.map(service => service.Floor))].filter(Boolean);
      setFloors(uniqueFloors);
      
      let filtered = services.filter(service => service.Location === selectedLocation);
      if (selectedFloor) {
        filtered = filtered.filter(service => service.Floor === selectedFloor);
      }
      setFilteredServices(filtered);
    } else {
      setFloors([]);
      setSelectedFloor('');
      setFilteredServices(services);
    }
  }, [selectedLocation, selectedFloor, services]);

  const handleProjectSelect = async (event, newValue) => {
    setSelectedProject(newValue);
    setLoading(true);
    setLoadingProgress(0);
    
    try {
      if (!newValue?.ProjectNumber) {
        setServices([]);
        return;
      }

      const projectNumber = newValue.ProjectNumber;
      const servicesData = await fetchServiceRequests(projectNumber);
      
      if (servicesData && servicesData.records) {
        setServices(servicesData.records);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services for project:", error);
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setSelectedFloor('');
  };

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value);
  };

  const calculateStatusData = (servicesList) => {
    const statusCounts = servicesList.reduce((acc, service) => {
      const status = service.ServiceStatus || 'Unknown';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    const chartData = statusData.status.map(status => ({
      value: statusCounts[status.name] || 0,
      label: status.name,
      color: status.color,
    }));

    const completedCount = statusCounts['Completed'] || 0;
    const completionPercentage = servicesList.length > 0 ? (completedCount / servicesList.length) * 100 : 0;

    return {
      data: chartData,
      completionPercentage
    };
  };

  const getServiceTypeStatusData = () => {
    const serviceTypes = [...new Set(filteredServices.map(service => service.RequestType))];
    const serviceTypeStatusData = {};

    serviceTypes.forEach(serviceType => {
      const serviceTypeServices = filteredServices.filter(service => service.RequestType === serviceType);
      serviceTypeStatusData[serviceType] = calculateStatusData(serviceTypeServices);
    });

    return serviceTypeStatusData;
  };

  const getRequestTypeChartData = () => {
    const typeCounts = filteredServices.reduce((acc, service) => {
      const type = service.RequestType || 'Unknown';
      if (!acc[type]) {
        acc[type] = {
          name: type,
          value: 1,
          color: typeColors[Object.keys(acc).length % typeColors.length]
        };
      } else {
        acc[type].value += 1;
      }
      return acc;
    }, {});

    return Object.values(typeCounts);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Box sx={{ mt: 2, width: '300px', textAlign: 'center' }}>
          <LinearProgress 
            variant="determinate" 
            value={loadingProgress} 
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Loading data...
          </Typography>
        </Box>
      </Box>
    );
  }

  const statusChartData = calculateStatusData(filteredServices);

  return (
    <Container maxWidth="xl" sx={{ py: 3, px: { xs: 1, sm: 2, md: 3 } }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Project Service Reports
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedProject}
              onChange={handleProjectSelect}
              options={projects}
              getOptionLabel={(option) => option ? `${option.ProjectName}` : ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Project"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          {services.length > 0 && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Filter by Location"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  variant="outlined"
                >
                  <MenuItem value="">All Locations</MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Filter by Floor"
                  value={selectedFloor}
                  onChange={handleFloorChange}
                  variant="outlined"
                  disabled={!selectedLocation}
                >
                  <MenuItem value="">All Floors</MenuItem>
                  {floors.map((floor) => (
                    <MenuItem key={floor} value={floor}>
                      {floor}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {(selectedLocation || selectedFloor) && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary" align="right">
                    Showing {filteredServices.length} of {services.length} services
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>

      {filteredServices.length > 0 && (
        <>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <StatusDonutChart
                value={statusChartData.completionPercentage}
                title="Overall Service Status Distribution"
                data={statusChartData.data}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RequestTypeBarChart
                data={getRequestTypeChartData()}
                theme={theme}
              />
            </Grid>
          </Grid>

          <Typography variant="h5" align="center" gutterBottom sx={{ mt: 4, mb: 3 }}>
            Individual Service Type Status
          </Typography>
          <Grid container spacing={3}>
            {Object.entries(getServiceTypeStatusData()).map(([serviceType, data]) => (
              <Grid item xs={12} md={6} lg={4} key={serviceType}>
                <StatusDonutChart
                  value={data.completionPercentage}
                  title={serviceType}
                  data={data.data}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {filteredServices.length === 0 && selectedProject && (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            No service data available for the selected filters
          </Typography>
        </Paper>
      )}
    </Container>
  );
};

export default Reporting;