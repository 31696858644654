import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import ClearIcon from "@mui/icons-material/Clear";
import ProjectConnectionStatus from "./ProjectConnectionStatus";
import ProjectForm from "./ProjectForm";
import TechnicianList from "./TechnicianList";
import ActionButtons from "./ActionButtons";
import { useUser } from "../../../UserContext";
import { fetchLocations } from "./s3controller";
import {
  calculateAvailability,
  calculateSkillScore,
  processTechnicians,
  filterTechniciansBySearch,
  validateProjectData,
  formatProjectData,
} from "./utils";

dayjs.extend(isBetween);

const EProjectCreation = () => {
  // Core project state
  const [projectNumber] = useState(uuidv4());
  const [projectName, setProjectName] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [projectDescription, setProjectDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  // UI state
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  // Data state
  const [locations, setLocations] = useState({});
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [locationError, setLocationError] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [rankedTechnicians, setRankedTechnicians] = useState([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [isLoadingTechnicians, setIsLoadingTechnicians] = useState(true);
  const [techniciansError, setTechniciansError] = useState(null);

  // Building Information
  const [customerBuildings, setCustomerBuildings] = useState(null);

  // Connection state
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [isConnecting, setIsConnecting] = useState(false);

  const { user } = useUser();

  const MAX_PROJECT_LENGTH = 64;
  const MAX_DESCRIPTION_LENGTH = 512;

  // Load locations from S3
  const loadLocations = useCallback(async () => {
    setIsLoadingLocations(true);
    setLocationError(null);

    try {
      const { data, error } = await fetchLocations();
      if (error) {
        setLocationError(error);
      } else {
        setLocations(data || {});
      }
    } catch (error) {
      setLocationError("An unexpected error occurred while loading locations");
      console.error("Location loading error:", error);
    } finally {
      setIsLoadingLocations(false);
    }
  }, []);

  // Initial WebSocket connection for technicians
  useEffect(() => {
    let ws = null;
    let technicianBuffer = [];

    const connectWebSocket = () => {
      setIsConnecting(true);
      setConnectionStatus("connecting");

      ws = new WebSocket(
        "wss://v96urhfxa9.execute-api.us-east-2.amazonaws.com/production"
      );

      ws.onopen = () => {
        setConnectionStatus("connected");
        setIsConnecting(false);
        ws.send(JSON.stringify({ action: "technicians" }));
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);

          switch (message.action) {
            case "technicians_start":
              technicianBuffer = [];
              setIsLoadingTechnicians(true);
              break;

            case "technician":
              if (message.data && message.data.technician) {
                technicianBuffer.push(message.data.technician);
              }
              break;

            case "technicians_complete":
              const processedTechnicians = technicianBuffer.map((tech) => ({
                ...tech,
                skillScore: calculateSkillScore(tech.Skills),
                availability: 100, // Default availability
              }));
              setTechnicians(processedTechnicians);
              setRankedTechnicians(processedTechnicians);
              setFilteredTechnicians(processedTechnicians);
              setIsLoadingTechnicians(false);
              ws.close();
              break;

            case "technicians_error":
              setTechniciansError(
                message.error || "Failed to load technicians"
              );
              break;

            default:
              console.log("Unhandled message type:", message.action);
          }
        } catch (error) {
          console.error("Error processing message:", error);
          setTechniciansError("Error processing technician data");
        }
      };

      ws.onclose = () => {
        setConnectionStatus("disconnected");
        setIsConnecting(false);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setTechniciansError("Failed to connect to technicians service");
        setConnectionStatus("error");
      };
    };

    connectWebSocket();

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  // Handle customer selection
  const handleCustomerSelect = useCallback(
    (event, newValue) => {
      setSelectedCustomer(newValue);
      if (newValue && locations[newValue]) {
        setCustomerBuildings(locations[newValue].BUILDINGS || []);
      } else {
        setCustomerBuildings(null);
      }
    },
    [locations]
  );

  // Effect for date changes and technician processing
  useEffect(() => {
    if (startDate && endDate && technicians.length > 0) {
      const { rankedTechnicians: ranked, filteredTechnicians: filtered } =
        processTechnicians(technicians, startDate, endDate, selectedMembers);
      setRankedTechnicians(ranked);
      setFilteredTechnicians(filtered);
    }
  }, [startDate, endDate, technicians, selectedMembers]);

  // Load initial data
  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  // Search and filter effect
  useEffect(() => {
    const filtered = filterTechniciansBySearch(
      rankedTechnicians,
      selectedMembers,
      searchInput
    );
    setFilteredTechnicians(filtered);
  }, [rankedTechnicians, searchInput, selectedMembers]);

  const handleAddMember = useCallback(
    (technician) => {
      if (!startDate || !endDate) {
        setError(
          "Please select project start and end dates before adding team members"
        );
        return;
      }

      if (selectedMembers.some((member) => member.Email === technician.Email)) {
        return;
      }

      const scheduleEntry = {
        projectNumber,
        projectName,
        projectDates: {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
      };

      const updatedTechnician = {
        ...technician,
        Scheduled: [...(technician.Scheduled || []), scheduleEntry],
        availability: calculateAvailability(
          [...(technician.Scheduled || []), scheduleEntry],
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        ),
      };

      setSelectedMembers((prev) => [...prev, updatedTechnician]);
      setTechnicians((current) =>
        current.map((tech) =>
          tech.Email === technician.Email ? updatedTechnician : tech
        )
      );
      setFilteredTechnicians((current) =>
        current.filter((tech) => tech.Email !== technician.Email)
      );
    },
    [projectNumber, projectName, startDate, endDate]
  );

  const handleRemoveMember = useCallback(
    (email) => {
      const removedMember = selectedMembers.find(
        (member) => member.Email === email
      );
      if (!removedMember) return;

      const updatedSchedule = removedMember.Scheduled.filter(
        (schedule) => schedule.projectNumber !== projectNumber
      );

      const updatedTechnician = {
        ...removedMember,
        Scheduled: updatedSchedule,
        availability: calculateAvailability(
          updatedSchedule,
          startDate?.format("YYYY-MM-DD"),
          endDate?.format("YYYY-MM-DD")
        ),
      };

      setSelectedMembers((prev) =>
        prev.filter((member) => member.Email !== email)
      );

      setTechnicians((current) =>
        current.map((tech) => (tech.Email === email ? updatedTechnician : tech))
      );

      setFilteredTechnicians((current) => {
        if (!current.some((tech) => tech.Email === email)) {
          return [...current, updatedTechnician].sort(
            (a, b) => b.overallScore - a.overallScore
          );
        }
        return current;
      });
    },
    [projectNumber, startDate, endDate]
  );

  const handleDateRangeChange = useCallback(
    (newStartDate, newEndDate) => {
      const updatedMembers = selectedMembers.map((member) => {
        const updatedSchedule = member.Scheduled.map((schedule) => {
          if (schedule.projectNumber === projectNumber) {
            return {
              ...schedule,
              projectDates: {
                startDate: newStartDate.format("YYYY-MM-DD"),
                endDate: newEndDate.format("YYYY-MM-DD"),
              },
            };
          }
          return schedule;
        });

        return {
          ...member,
          Scheduled: updatedSchedule,
          availability: calculateAvailability(
            updatedSchedule,
            newStartDate.format("YYYY-MM-DD"),
            newEndDate.format("YYYY-MM-DD")
          ),
        };
      });

      const updatedTechnicians = technicians.map((tech) => {
        const selectedMember = updatedMembers.find(
          (member) => member.Email === tech.Email
        );
        if (selectedMember) {
          return selectedMember;
        }

        return {
          ...tech,
          availability: calculateAvailability(
            tech.Scheduled,
            newStartDate.format("YYYY-MM-DD"),
            newEndDate.format("YYYY-MM-DD")
          ),
        };
      });

      setSelectedMembers(updatedMembers);
      setTechnicians(updatedTechnicians);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [projectNumber, selectedMembers, technicians]
  );

  const handleTechnicianUpdate = useCallback((updatedTechnicians) => {
    console.log("Running Update In EprojectCreation:", updatedTechnicians);
    setFilteredTechnicians(updatedTechnicians);
  }, []); // Empty dependency array since we're just doing a direct state update

  const handleTechniciansUpdate = useCallback((updatedTechnicians) => {
    console.log("Running Update In EprojectCreation:", updatedTechnicians);
    
    // Batch the state updates using a function to ensure we're working with the latest state
    setTechnicians(prevTechnicians => {
      const newTechnicians = updatedTechnicians;
      
      // Process the updated technicians immediately
      const { rankedTechnicians: ranked, filteredTechnicians: filtered } =
        processTechnicians(newTechnicians, startDate, endDate, selectedMembers);
      
      // Update the other states
      setRankedTechnicians(ranked);
      setFilteredTechnicians(filtered);
      
      return newTechnicians;
    });
  }, [startDate, endDate, selectedMembers]);

  const handleClear = useCallback(() => {
    setProjectName("");
    setSelectedServices([]);
    setSelectedCustomer(null);
    setProjectDescription("");
    setStartDate(null);
    setEndDate(null);
    setError("");
    setSearchInput("");

    // Reset all selected members' schedules
    const updatedTechnicians = technicians.map((tech) => {
      if (selectedMembers.some((member) => member.Email === tech.Email)) {
        const updatedSchedule = tech.Scheduled.filter(
          (schedule) => schedule.projectNumber !== projectNumber
        );
        return {
          ...tech,
          Scheduled: updatedSchedule,
          availability: 100,
        };
      }
      return tech;
    });

    setSelectedMembers([]);
    setTechnicians(updatedTechnicians);
    setFilteredTechnicians(updatedTechnicians);
  }, [projectNumber, technicians, selectedMembers]);

  const handleSave = async () => {
    setIsSaving(true);
    setError("");

    try {
      validateProjectData({
        projectName,
        selectedCustomer,
        startDate,
        endDate,
        selectedMembers,
        selectedServices,
      });

      const projectData = formatProjectData({
        projectNumber,
        projectName,
        selectedCustomer,
        projectDescription,
        startDate,
        endDate,
        user,
        selectedMembers,
        selectedServices,
      });

      console.log("Project data to save:", projectData);
      // Simulate saving delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      handleClear();
    } catch (error) {
      setError(error.message || "Failed to save project");
      console.error("Save error:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const isValid = useMemo(
    () =>
      projectName.trim() &&
      projectName !== "PRJ-" &&
      selectedCustomer &&
      startDate &&
      endDate &&
      !endDate.isBefore(startDate) &&
      selectedMembers.length > 0 &&
      selectedServices.length > 0 &&
      !error,
    [
      projectName,
      selectedCustomer,
      startDate,
      endDate,
      selectedMembers.length,
      selectedServices.length,
      error,
    ]
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h5">Create New Project</Typography>
                <ProjectConnectionStatus
                  connectionStatus={connectionStatus}
                  techniciansCount={technicians.length}
                  isLoadingTechnicians={isLoadingTechnicians}
                  techniciansError={techniciansError}
                  isConnecting={isConnecting}
                />
              </Box>

              <ProjectForm
                projectName={projectName}
                onProjectNameChange={(e) => setProjectName(e.target.value)}
                projectDescription={projectDescription}
                onDescriptionChange={(e) =>
                  setProjectDescription(e.target.value)
                }
                selectedCustomer={selectedCustomer}
                onCustomerChange={handleCustomerSelect}
                customerOptions={Object.keys(locations).sort()}
                isLoadingLocations={isLoadingLocations}
                locationError={locationError}
                startDate={startDate}
                onStartDateChange={setStartDate}
                endDate={endDate}
                onEndDateChange={setEndDate}
                userEmail={user?.EmailAddress}
                error={error}
                setError={setError}
                MAX_PROJECT_LENGTH={MAX_PROJECT_LENGTH}
                MAX_DESCRIPTION_LENGTH={MAX_DESCRIPTION_LENGTH}
                selectedMembers={selectedMembers}
                onRemoveMember={handleRemoveMember}
                onServiceChange={(serviceData) =>
                  setSelectedServices((prev) => [...prev, serviceData])
                }
                customerBuildings={customerBuildings}
                locations={locations}
              />

              <ActionButtons
                onSave={handleSave}
                onClear={handleClear}
                isValid={isValid}
                connectionStatus={connectionStatus}
                isSaving={isSaving}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Available Technicians
              </Typography>

              <TextField
                fullWidth
                label="Search Technicians"
                variant="outlined"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                  endAdornment: searchInput ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchInput("")} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />

              <TechnicianList
                technicians={filteredTechnicians}
                onAddMember={handleAddMember}
                selectedMembers={selectedMembers}
                startDate={startDate}
                endDate={endDate}
                onTechniciansUpdate={handleTechnicianUpdate}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EProjectCreation;
