import React from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { motion, AnimatePresence } from "framer-motion";

const TC_ProjectList = ({ scheduled, isDetailsLoaded, onDelete }) => {
  const theme = useTheme();

  if (!scheduled || !isDetailsLoaded) return null;

  return (
    <List dense>
      <AnimatePresence>
        {scheduled.map((project) => (
          <motion.div
            key={project.projectNumber}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDelete(project.projectNumber)}
                  sx={{
                    color: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor: theme.palette.error.light + "20",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={project.projectName || `Project ${project.projectNumber}`}
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {project.projectDates.startDate} to{" "}
                    {project.projectDates.endDate}
                  </Typography>
                }
              />
            </ListItem>
          </motion.div>
        ))}
      </AnimatePresence>
    </List>
  );
};

TC_ProjectList.propTypes = {
  scheduled: PropTypes.arrayOf(
    PropTypes.shape({
      projectNumber: PropTypes.string.isRequired,
      projectName: PropTypes.string,
      projectDates: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  isDetailsLoaded: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TC_ProjectList;