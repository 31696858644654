import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Button,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { downloadData, uploadData } from 'aws-amplify/storage';

// Define skill categories outside component to prevent recreation
const SKILL_CATEGORIES = {
  'Network Services': ['Wireless AP Installation', 'LTE Gateway Configuration', 'Network Infrastructure'],
  'Camera Systems': ['Indoor Camera Installation', 'Outdoor Camera Installation', 'PTZ Camera Systems', 'Video Management Systems'],
  'Access Control': ['Door Controller Installation', 'Card Reader Systems', 'Wireless Hub Configuration', 'Door Strike Installation', 'Access Control Programming'],
  'Sensors': ['Motion Sensor Installation', 'Environmental Sensor Systems', 'Door Position Sensors', 'Intrusion Detection Systems'],
  'Infrastructure': ['Indoor Cable Installation', 'Outdoor Cable Installation', 'Power Supply Systems', 'Hardware Mounting'],
  'Specialized Systems': ['Intercom Systems', 'Maglock Installation', 'Guest Management Systems', 'Viewing Station Setup'],
  'Core Competencies': ['System Programming', 'Technical Documentation', 'Quality Assurance', 'Troubleshooting', 'Project Management', 'Safety Compliance']
};

// S3 service functions
const techniciansService = {
  fetchTechniciansBasicInfo: async () => {
    try {
      const downloadResult = await downloadData({
        key: 'databases/eTechnicians.json',
        options: {
          bucket: 'CiServiceDesk'
        }
      }).result;
      const text = await downloadResult.body.text();
      const techData = JSON.parse(text);
      const basicInfo = techData.Technicians.map(tech => ({
        Name: tech.Name,
        Email: tech.Email,
        Phone: tech.Phone
      }));
      return { data: basicInfo, error: null };
    } catch (error) {
      console.error('Error fetching technicians:', error);
      return {
        data: null,
        error: 'Failed to load technicians data'
      };
    }
  },

  fetchTechnicianDetails: async (email) => {
    try {
      const downloadResult = await downloadData({
        key: 'databases/eTechnicians.json',
        options: {
          bucket: 'CiServiceDesk'
        }
      }).result;
      const text = await downloadResult.body.text();
      const techData = JSON.parse(text);
      const technician = techData.Technicians.find(tech => tech.Email === email);
      
      if (!technician) {
        return { data: null, error: 'Technician not found' };
      }
      return { data: technician, error: null };
    } catch (error) {
      console.error('Error fetching technician details:', error);
      return {
        data: null,
        error: 'Failed to load technician details'
      };
    }
  },

  updateTechnician: async (updatedTechnician) => {
    try {
      // First fetch current data
      const downloadResult = await downloadData({
        key: 'databases/eTechnicians.json',
        options: {
          bucket: 'CiServiceDesk'
        }
      }).result;
      const text = await downloadResult.body.text();
      const techData = JSON.parse(text);
      
      // Update the specific technician
      const updatedTechnicians = techData.Technicians.map(tech => 
        tech.Email === updatedTechnician.Email ? updatedTechnician : tech
      );

      // Prepare the updated file
      const updatedData = {
        Technicians: updatedTechnicians
      };

      // Upload the updated file using uploadData
      const uploadResult = await uploadData({
        key: 'databases/eTechnicians.json',
        data: JSON.stringify(updatedData, null, 2),
        options: {
          bucket: 'CiServiceDesk',
          contentType: 'application/json'
        }
      }).result;

      return { success: true, error: null };
    } catch (error) {
      console.error('Error updating technician:', error);
      return {
        success: false,
        error: 'Failed to update technician data'
      };
    }
  }
};

const SkillsManagement = () => {
  const [technicians, setTechnicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [technicianDetails, setTechnicianDetails] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [detailsError, setDetailsError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    let mounted = true;

    const loadTechnicians = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const { data, error } = await techniciansService.fetchTechniciansBasicInfo();
        
        if (mounted) {
          if (error) {
            setError(error);
          } else {
            setTechnicians(data || []);
          }
        }
      } catch (err) {
        if (mounted) {
          setError('Failed to load technicians. Please try again.');
          console.error('Error loading technicians:', err);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadTechnicians();

    return () => {
      mounted = false;
    };
  }, []);

  const handleTechnicianSelect = async (event, newValue) => {
    setSelectedTechnician(newValue);
    setTechnicianDetails(null);
    setSaveSuccess(false);
    setSaveError(null);
    setDetailsError(null);
    setHasUnsavedChanges(false);

    if (newValue) {
      setLoadingDetails(true);
      try {
        const { data, error } = await techniciansService.fetchTechnicianDetails(newValue.Email);
        if (error) {
          setDetailsError(error);
        } else {
          // Initialize any missing skills with 0
          const fullSkillSet = Object.values(SKILL_CATEGORIES).flat();
          const initializedSkills = { ...data.Skills };
          fullSkillSet.forEach(skill => {
            if (!(skill in initializedSkills)) {
              initializedSkills[skill] = 0;
            }
          });
          setTechnicianDetails({ ...data, Skills: initializedSkills });
        }
      } catch (err) {
        setDetailsError('Failed to load technician details. Please try again.');
        console.error('Error loading technician details:', err);
      } finally {
        setLoadingDetails(false);
      }
    }
  };

  const handleSkillChange = (skillName, newValue) => {
    setTechnicianDetails(prev => ({
      ...prev,
      Skills: {
        ...prev.Skills,
        [skillName]: newValue
      }
    }));
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  };

  const handleSave = async () => {
    if (!technicianDetails) return;

    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    try {
      const { success, error } = await techniciansService.updateTechnician(technicianDetails);

      if (success) {
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      } else {
        setSaveError(error || 'Failed to save changes. Please try again.');
      }
    } catch (err) {
      setSaveError('An unexpected error occurred. Please try again.');
      console.error('Error saving technician details:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const renderSkillsCategories = () => {
    if (!technicianDetails) return null;

    return Object.entries(SKILL_CATEGORIES).map(([category, skills]) => (
      <Accordion key={category}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${category}-content`}
          id={`${category}-header`}
        >
          <Typography variant="h6">{category}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {skills.map(skill => (
              <Grid item xs={12} key={skill}>
                <Typography gutterBottom>{skill}</Typography>
                <Slider
                  value={technicianDetails.Skills[skill] || 0}
                  onChange={(e, newValue) => handleSkillChange(skill, newValue)}
                  marks={[
                    { value: 0, label: '0%' },
                    { value: 25, label: '25%' },
                    { value: 50, label: '50%' },
                    { value: 75, label: '75%' },
                    { value: 100, label: '100%' }
                  ]}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                  valueLabelFormat={value => `${value}%`}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Skills Management
          </Typography>

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <>
              <Autocomplete
                options={technicians}
                getOptionLabel={(option) => `${option.Name} (${option.Email})`}
                value={selectedTechnician}
                onChange={handleTechnicianSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Technician"
                    variant="outlined"
                    fullWidth
                  />
                )}
                sx={{ mb: 4 }}
                isOptionEqualToValue={(option, value) => option.Email === value.Email}
              />

              {loadingDetails && (
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              )}

              {detailsError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {detailsError}
                </Alert>
              )}

              {technicianDetails && !loadingDetails && (
                <>
                  <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                      Contact Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          value={technicianDetails.Phone}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={technicianDetails.Email}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {renderSkillsCategories()}

                  <Box mt={4} display="flex" alignItems="center" gap={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={isSaving || !hasUnsavedChanges}
                    >
                      {isSaving ? 'Saving...' : 'Save Changes'}
                    </Button>
                    
                    {isSaving && <CircularProgress size={24} />}
                    
                    {saveSuccess && (
                      <Alert severity="success">
                        Changes saved successfully
                      </Alert>
                    )}
                    
                    {saveError && (
                      <Alert severity="error">
                        {saveError}
                      </Alert>
                    )}

                    {hasUnsavedChanges && !saveSuccess && !saveError && (
                      <Alert severity="info">
                        You have unsaved changes
                      </Alert>
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default SkillsManagement;