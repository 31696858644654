import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import servicesData from "../../Data/servicesDatabase.json";

const ServiceGroupsSection = ({ projectData, setProjectData, locationsData }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [serviceCount, setServiceCount] = useState("");

  const allServices = servicesData.response[0].Network.concat(
    servicesData.response[0]["Physical Security"],
    servicesData.response[0]["Hardware Installations"]
  );

  // Reset building and floor when customer changes
  useEffect(() => {
    setSelectedBuilding("");
    setSelectedFloor("");
  }, [projectData.customerName]);

  const handleAddServiceGroup = () => {
    if (selectedService) {
      const newGroups = selectedService.Tasks.map((task) => {
        const cleanProjectName = projectData.projectName.replace("PRJ-", "").replace(/\s+/g, "");
        const cleanServiceName = selectedService["Service Type"].replace(/\s+/g, "");
        const cleanTaskName = task.name.replace(/^\d+\s*-?\s*/, "").replace(/\s+/g, "");
        return {
          name: `AG-${cleanProjectName}-${cleanServiceName}-${cleanTaskName}`,
          service: selectedService["Service Type"],
          task: task.name,
          members: [],
        };
      });
      
      setProjectData(prevData => ({
        ...prevData,
        serviceGroups: [...prevData.serviceGroups, ...newGroups]
      }));
      setSelectedService(null);
    }
  };

  const handleRemoveServiceGroup = (groupName) => {
    setProjectData(prevData => ({
      ...prevData,
      serviceGroups: prevData.serviceGroups.filter(group => group.name !== groupName)
    }));
  };

  const handleAddUserToGroup = (groupName, user) => {
    setProjectData(prevData => ({
      ...prevData,
      serviceGroups: prevData.serviceGroups.map(group => {
        if (group.name === groupName && !group.members.some(member => member.EmailAddress === user.EmailAddress)) {
          return { ...group, members: [...group.members, user] };
        }
        return group;
      })
    }));
  };

  const handleRemoveUserFromGroup = (groupName, userEmail) => {
    setProjectData(prevData => ({
      ...prevData,
      serviceGroups: prevData.serviceGroups.map(group => {
        if (group.name === groupName) {
          return {
            ...group,
            members: group.members.filter(member => member.EmailAddress !== userEmail)
          };
        }
        return group;
      })
    }));
  };

  const handleDeployService = (service) => {
    if (!selectedBuilding || !selectedFloor || !serviceCount) {
      alert("Please select a building, floor, and enter the number of services before deploying.");
      return;
    }

    const serviceDefinition = {
      service: service,
      building: selectedBuilding,
      floor: selectedFloor,
      count: serviceCount,
      groups: projectData.serviceGroups.filter(group => group.service === service),
      deployed: false,
    };
    
    setProjectData(prevData => ({
      ...prevData,
      completeServiceDefinitions: [...prevData.completeServiceDefinitions, serviceDefinition]
    }));
    
    setSelectedBuilding("");
    setSelectedFloor("");
    setServiceCount("");
  };

  const isServiceGroupDefined = (serviceType) => {
    return projectData.serviceGroups.some(group => group.service === serviceType && group.members.length > 0);
  };

  const getUniqueServices = () => {
    return [...new Set(projectData.serviceGroups.map(group => group.service))];
  };

  const getAvailableFloors = () => {
    // Return empty array if no customer or building selected
    if (!projectData.customerName || !selectedBuilding) return [];
    
    // Find the selected building in the locations data
    const building = locationsData[projectData.customerName]?.BUILDINGS.find(
      b => b["Building Name"] === selectedBuilding
    );
    
    if (!building) return [];
    
    // Ensure Floors is a number and greater than 0
    const numFloors = parseInt(building.Floors);
    if (isNaN(numFloors) || numFloors <= 0) return [];
    
    // Create array of floor numbers from 1 to numFloors
    return Array.from({ length: numFloors }, (_, index) => index + 1);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create Service Groups
        </Typography>
        
        <Autocomplete
          id="service-select"
          options={allServices}
          getOptionLabel={(option) => option["Service Type"]}
          value={selectedService}
          onChange={(event, newValue) => {
            setSelectedService(newValue);
          }}
          isOptionEqualToValue={(option, value) => option["Service Type"] === value["Service Type"]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Service"
              placeholder="Service"
            />
          )}
        />
        
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddServiceGroup}
          disabled={!selectedService}
          sx={{ mt: 2 }}
        >
          Add Service Group
        </Button>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Project Service Groups
        </Typography>
        
        {projectData.customerName === "" && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Please select a customer in the Project Details section first.
          </Alert>
        )}

        {getUniqueServices().map((service) => (
          <Accordion key={service}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{service}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell>Task</TableCell>
                      <TableCell>Members</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectData.serviceGroups
                      .filter((group) => group.service === service)
                      .map((group) => (
                        <TableRow key={group.name}>
                          <TableCell>{group.name}</TableCell>
                          <TableCell>{group.task}</TableCell>
                          <TableCell>
                            <Autocomplete
                              multiple
                              id={`user-select-${group.name}`}
                              options={projectData.selectedUsers}
                              getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                              value={group.members}
                              onChange={(event, newValue) => {
                                const lastAddedUser = newValue[newValue.length - 1];
                                if (lastAddedUser) {
                                  handleAddUserToGroup(group.name, lastAddedUser);
                                }
                              }}
                              isOptionEqualToValue={(option, value) => option.EmailAddress === value.EmailAddress}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Add Members"
                                  placeholder="Users"
                                />
                              )}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={option.EmailAddress}
                                    label={`${option.FirstName} ${option.LastName}`}
                                    {...getTagProps({ index })}
                                    onDelete={() => handleRemoveUserFromGroup(group.name, option.EmailAddress)}
                                  />
                                ))
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleRemoveServiceGroup(group.name)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
              {isServiceGroupDefined(service) && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Building</InputLabel>
                      <Select
                        value={selectedBuilding}
                        onChange={(e) => {
                          setSelectedBuilding(e.target.value);
                          setSelectedFloor(""); // Reset floor when building changes
                        }}
                      >
                        {locationsData[projectData.customerName]?.BUILDINGS.map(
                          (building) => (
                            <MenuItem
                              key={building["Building Number"]}
                              value={building["Building Name"]}
                            >
                              {building["Building Name"]}
                            </MenuItem>
                          )
                        ) || []}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Floor</InputLabel>
                      <Select
                        value={selectedFloor}
                        onChange={(e) => setSelectedFloor(e.target.value)}
                      >
                        {getAvailableFloors().map((floor) => (
                          <MenuItem key={floor} value={floor}>
                            {floor}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Count"
                      type="number"
                      value={serviceCount}
                      onChange={(e) => setServiceCount(e.target.value)}
                      inputProps={{ min: 1, max: 99 }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDeployService(service)}
                      disabled={!selectedBuilding || !selectedFloor || !serviceCount}
                    >
                      Deploy Service
                    </Button>
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </CardContent>
    </Card>
  );
};

export default ServiceGroupsSection;