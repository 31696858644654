import { downloadData } from 'aws-amplify/storage';

export const fetchLocations = async () => {
    console.log('Fetching locations data from S3');
    
    try {
        const downloadResult = await downloadData({
            key: 'databases/locations.json',
            options: {
                bucket: 'CiServiceDesk',
                cacheControl: 'no-cache',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                },
                queryStringParameters: {
                    't': Date.now()
                }
            }
        }).result;

        if (!downloadResult || !downloadResult.body) {
            throw new Error('No data received from S3');
        }

        const text = await downloadResult.body.text();
        if (!text) {
            throw new Error('Empty response from S3');
        }

        const locationsData = JSON.parse(text);
        console.log('Locations data fetched successfully');
        
        return { 
            data: locationsData, 
            error: null 
        };
    } catch (error) {
        console.error('Error fetching locations:', error);
        return { 
            data: null, 
            error: 'Failed to load location data. Please try again later.'
        };
    }
};

export const fetchTechniciansBasicInfo = async () => {
    try {
        const downloadResult = await downloadData({
            key: 'databases/eTechnicians.json',
            options: {
                bucket: 'CiServiceDesk',
                cacheControl: 'no-cache',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                },
                // Add timestamp to force fresh download
                queryStringParameters: {
                    't': Date.now()
                }
            }
        }).result;
        const text = await downloadResult.body.text();
        const techData = JSON.parse(text);
        const basicInfo = techData.Technicians.map(tech => ({
            Name: tech.Name,
            Email: tech.Email,
            Phone: tech.Phone,
            Role: tech.Role,
            Skills: tech.Skills,
            Scheduled: tech.Scheduled
        }));
        console.log("Basic Info:", basicInfo);
        return { data: basicInfo, error: null };
    } catch (error) {
        console.error('Error fetching technicians:', error);
        return {
            data: null,
            error: 'Failed to load technicians data'
        };
    }
};

export const fetchServices = async () => {
    try {
        const downloadResult = await downloadData({
            key: 'databases/eServicesDatabase.json',
            options: {
                bucket: 'CiServiceDesk',
                cacheControl: 'no-cache',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                },
                // Add timestamp to force fresh download
                queryStringParameters: {
                    't': Date.now()
                }
            }
        }).result;
        const text = await downloadResult.body.text();
        const servicesData = JSON.parse(text);
        return { data: servicesData, error: null };
    } catch (error) {
        console.error('Error fetching services:', error);
        return {
            data: null,
            error: 'Failed to load services data'
        };
    }
};