// WebSocket connection constants
const WS_URL = 'wss://v96urhfxa9.execute-api.us-east-2.amazonaws.com/production';

// Websocket state
let ws = null;
let connectionStatus = 'disconnected';
let onStatusChange = null;
let reconnectAttempts = 0;
const maxReconnectAttempts = 5;

// Technicians data reconstruction state
let technicianBuffer = [];
let expectedTechnicianCount = 0;
let onTechniciansUpdate = null;

const updateStatus = (status) => {
    console.log('WebSocket status updated to:', status);
    connectionStatus = status;
    if (onStatusChange) {
        onStatusChange(status);
    }
};

const resetTechnicianBuffer = () => {
    technicianBuffer = [];
    expectedTechnicianCount = 0;
};

const requestTechnicians = () => {
    if (ws?.readyState === WebSocket.OPEN) {
        const message = {
            action: 'technicians'
        };
        console.log('Requesting technicians data:', message);
        ws.send(JSON.stringify(message));
    } else {
        console.error('WebSocket is not connected');
    }
};

const processTechnicianMessage = (message) => {
    try {
        const { action, data, error } = message;
        console.log('Processing message:', { action, data });

        switch (action) {
            case 'technicians_start':
                console.log('Starting technicians data reception. Expected count:', data.totalTechnicians);
                resetTechnicianBuffer();
                expectedTechnicianCount = data.totalTechnicians;
                if (onTechniciansUpdate) {
                    onTechniciansUpdate({ isLoading: true, Technicians: [] });
                }
                break;

            case 'technician':
                console.log(`Received technician ${data.metadata.index + 1}/${data.metadata.total}`);
                technicianBuffer.push(data.technician);
                break;
                
            case 'technicians_complete':
                console.log('All technicians received. Reconstructing final data...');
                const reconstructedData = {
                    Technicians: technicianBuffer,
                    isLoading: false,
                    timestamp: data.timestamp
                };
                console.log('Reconstructed technicians data:', reconstructedData);
                
                if (onTechniciansUpdate) {
                    onTechniciansUpdate(reconstructedData);
                }
                
                resetTechnicianBuffer();
                break;

            case 'technician_updated':
                console.log('Technician updated:', data.technician);
                // Update the technicians list with the new data
                if (onTechniciansUpdate) {
                    onTechniciansUpdate(current => {
                        const updatedTechnicians = current.Technicians.map(tech =>
                            tech.Email === data.technician.Email ? data.technician : tech
                        );
                        return {
                            ...current,
                            Technicians: updatedTechnicians,
                            timestamp: data.timestamp
                        };
                    });
                }
                break;

            case 'updatetech_success':
                console.log('Technician update successful:', data.message);
                break;

            case 'updatetech_error':
                console.error('Error updating technician:', error);
                break;

            case 'technicians_error':
                console.error('Error receiving technicians:', error);
                resetTechnicianBuffer();
                break;

            default:
                console.log('Unhandled message action:', action);
        }
    } catch (error) {
        console.error('Error processing technician message:', error);
    }
};

export const connectWebSocket = () => {
    if (ws?.readyState === WebSocket.OPEN) {
        console.log('WebSocket already connected');
        requestTechnicians(); // Request technicians even if already connected
        return;
    }

    console.log('Attempting to connect to:', WS_URL);
    updateStatus('connecting');

    try {
        ws = new WebSocket(WS_URL);

        ws.onopen = () => {
            console.log('WebSocket connection established');
            updateStatus('connected');
            reconnectAttempts = 0;
            // Request technicians data immediately after connection
            requestTechnicians();
        };

        ws.onclose = (event) => {
            console.log('WebSocket closed with code:', event.code);
            console.log('Close reason:', event.reason);
            updateStatus('disconnected');
            resetTechnicianBuffer();
            
            if (event.code === 1006) {
                console.log('Abnormal closure detected');
                if (reconnectAttempts < maxReconnectAttempts) {
                    const delay = Math.min(1000 * Math.pow(2, reconnectAttempts), 10000);
                    console.log(`Attempting to reconnect in ${delay}ms (attempt ${reconnectAttempts + 1}/${maxReconnectAttempts})`);
                    setTimeout(() => {
                        reconnectAttempts++;
                        connectWebSocket();
                    }, delay);
                } else {
                    console.error('Maximum reconnection attempts reached');
                    updateStatus('error');
                }
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            if (error.message) console.error('Error message:', error.message);
            if (error.error) console.error('Error details:', error.error);
        };

        ws.onmessage = (event) => {
            try {
                console.log('Raw message received:', event.data);
                const message = JSON.parse(event.data);
                console.log('Parsed message:', message);
                processTechnicianMessage(message);
            } catch (error) {
                console.error('Error processing message:', error);
                console.log('Raw message that failed to process:', event.data);
            }
        };
    } catch (error) {
        console.error('Error creating WebSocket:', error);
        updateStatus('error');
    }
};

export const disconnectWebSocket = () => {
    if (ws) {
        console.log('Initiating WebSocket disconnect');
        ws.close();
        ws = null;
        reconnectAttempts = 0;
        updateStatus('disconnected');
        resetTechnicianBuffer();
    }
};

export const setWebSocketStatusChangeCallback = (callback) => {
    onStatusChange = callback;
};

export const setTechniciansUpdateCallback = (callback) => {
    onTechniciansUpdate = callback;
};

export const getWebSocketStatus = () => connectionStatus;

// Export WebSocket instance for direct access in components
export { ws };

// Export requestTechnicians for manual requests if needed
export { requestTechnicians };