import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import { getUrl, uploadData } from "aws-amplify/storage";

const ProjectFilesSection = ({ projectData, setProjectData }) => {
    const [previewFile, setPreviewFile] = useState(null);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileExtension = file.name.split(".").pop().toLowerCase();
        const uuid = uuidv4();
        const newFileName = `${uuid}_${file.name}`;

        console.log("UPLOAD WITH PATH", `projectFiles/${newFileName}`);
        try {
            const result = await uploadData({
                key: `projectFiles/${newFileName}`,
                data: file,
                options: {
                    bucket: "CiServiceDesk",
                    contentType: file.type || "application/octet-stream",
                },
            }).result;
            console.log("Upload successful", result);

            const newFile = {
                name: file.name,
                uuid: uuid,
                type: fileExtension,
            };
            setProjectData((prevData) => ({
                ...prevData,
                projectFiles: [...prevData.projectFiles, newFile],
                uploadedFiles: [...prevData.uploadedFiles, newFileName],
            }));
            alert("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Failed to upload file. Please try again.");
        }
    };

    const getFileIcon = (fileType) => {
        switch (fileType) {
            case "pdf":
                return <PictureAsPdfIcon />;
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <ImageIcon />;
            case "doc":
            case "docx":
                return <DescriptionIcon />;
            case "xls":
            case "xlsx":
                return <InsertDriveFileIcon style={{ color: "#217346" }} />;
            default:
                return <InsertDriveFileIcon />;
        }
    };

    const handlePreviewFile = (file) => {
        setPreviewFile(file);
    };

    const handleClosePreview = () => {
        setPreviewFile(null);
    };

    const RenderFilePreview = ({ file }) => {
        useEffect(() => {
            if (!file) return;

            const fetchFileUrl = async () => {
                const url = await getUrl({
                    key: `projectFiles/${file.uuid}_${file.name}`,
                });
                setFileUrl(url.url.toString());
            };

            fetchFileUrl();
        }, [file]);

        const [fileUrl, setFileUrl] = useState(null);

        if (!file) return null;

        switch (previewFile.type) {
            case "pdf":
                return (
                    <iframe
                        src={fileUrl}
                        width="100%"
                        height="500px"
                        title="PDF Preview"
                    />
                );
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return (
                    <img
                        src={fileUrl}
                        alt={file.name}
                        style={{ maxWidth: "100%", maxHeight: "500px" }}
                    />
                );
            default:
                return (
                    <Typography>
                        Preview not available for this file type.
                    </Typography>
                );
        }
    };

    const handleRemoveFile = (uuid) => {
        setProjectData((prevData) => ({
            ...prevData,
            projectFiles: prevData.projectFiles.filter(
                (file) => file.uuid !== uuid,
            ),
            uploadedFiles: prevData.uploadedFiles.filter(
                (fileName) => !fileName.startsWith(uuid),
            ),
        }));
    };

    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Project Files
                </Typography>
                <input
                    accept="*/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                />
                <label htmlFor="raised-button-file">
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<UploadFileIcon />}
                    >
                        Upload File
                    </Button>
                </label>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Type</TableCell>
                                <TableCell>File Name</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectData.projectFiles.map((file) => (
                                <TableRow key={file.uuid}>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handlePreviewFile(file)
                                            }
                                        >
                                            {getFileIcon(file.type)}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{file.name}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handleRemoveFile(file.uuid)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    open={!!previewFile}
                    onClose={handleClosePreview}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>{previewFile?.name}</DialogTitle>
                    <DialogContent>
                        <RenderFilePreview file={previewFile} />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default ProjectFilesSection;
