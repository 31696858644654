import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography, CircularProgress, Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useUser } from "../../../UserContext";
import ProjectDetailsForm from "./ProjectComponents/ProjectDetailsForm";
import ProjectMembersSection from "./ProjectComponents/ProjectMembersSection";
import ServiceGroupsSection from "./ProjectComponents/ServiceGroupsSection";
import ServiceDefinitionsSection from "./ProjectComponents/ServiceDefinitionsSection";
import ProjectFilesSection from "./ProjectComponents/ProjectFilesSection";
import ProjectActions from "./ProjectComponents/ProjectActions";
import { fetchProjectFromS3, updateProject, createServiceRequest } from "../../ApiServices/ApiServices";
import { uploadToS3, createServicesPayload } from "./ProjectComponents/utils";
import { v4 as uuidv4 } from "uuid";
import dayjs from 'dayjs';
import srStatus from '../Data/srStatus.json';
import { downloadData } from "aws-amplify/storage";

const ProjectCreation = () => {
  const { allUsers, user } = useUser();
  const { projectNumber } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locationsData, setLocationsData] = useState({});
  const [projectData, setProjectData] = useState({
    projectName: "PRJ-",
    customerName: "",
    projectDescription: "",
    startDate: null,
    endDate: null,
    projectOwner: "",
    selectedUsers: [],
    serviceGroups: [],
    completeServiceDefinitions: [],
    projectFiles: [],
    uploadedFiles: [],
    projectUuid: null,
    projectDeployed: false,
    projectStatus: "New", // Default status
  });

  useEffect(() => {
    const initializeProject = async () => {
      if (!allUsers || !user) {
        console.log("Context data not available yet");
        return;
      }

      try {
        // Fetch locations data
        const downloadResult = await downloadData({
          key: 'databases/locations.json'
        }).result;
        const text = await downloadResult.body.text();
        const locations = JSON.parse(text);
        setLocationsData(locations);
        console.log("Locations data loaded successfully");

        setProjectData(prevData => ({
          ...prevData,
          projectOwner: user ? `${user.EmailAddress}` : "",
        }));

        if (projectNumber) {
          setIsEditMode(true);
          await loadProjectData(projectNumber);
        }
      } catch (error) {
        console.error("Error loading locations:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeProject();
  }, [projectNumber, allUsers.response, user]);

  const loadProjectData = async (projectNumber) => {
    try {
      const data = await fetchProjectFromS3(projectNumber);
      console.log("Fetched project data:", data);
      setProjectData(prevData => ({
        ...prevData,
        ...data,
        projectName: data.ProjectName || "PRJ-",
        customerName: data.CustomerName || "",
        projectDescription: data.ProjectDescription || "",
        startDate: data.StartDate ? dayjs(data.StartDate) : null,
        endDate: data.EndDate ? dayjs(data.EndDate) : null,
        projectOwner: data.ProjectOwner || "",
        selectedUsers: data.ProjectMembers || [],
        serviceGroups: data.ServiceGroups || [],
        completeServiceDefinitions: data.ServiceDefinitions || [],
        projectFiles: data.ProjectFiles || [],
        uploadedFiles: data.UploadedFiles || [],
        projectUuid: data.ProjectNumber || null,
        projectDeployed: data.ProjectDeployed || false,
        projectStatus: data.ProjectStatus || "New", // Use "New" as default if no status is present
      }));
    } catch (error) {
      console.error("Error loading project data:", error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const projectNumber = isEditMode ? projectData.projectUuid : uuidv4();
      const s3Data = {
        ProjectOwner: projectData.projectOwner,
        ProjectNumber: projectNumber,
        ProjectName: projectData.projectName,
        ProjectStatus: projectData.projectStatus,
        CustomerName: projectData.customerName,
        ProjectDescription: projectData.projectDescription,
        StartDate: projectData.startDate ? projectData.startDate.toISOString() : null,
        EndDate: projectData.endDate ? projectData.endDate.toISOString() : null,
        ProjectMemberCount: projectData.selectedUsers.length,
        ServiceGroupsCount: projectData.serviceGroups.length,
        ServiceDefinitionsCount: projectData.completeServiceDefinitions.length,
        ProjectMembers: projectData.selectedUsers,
        ServiceGroups: projectData.serviceGroups,
        ServiceDefinitions: projectData.completeServiceDefinitions,
        ProjectDeployed: projectData.projectDeployed,
        ProjectFiles: projectData.projectFiles,
        UploadedFiles: projectData.uploadedFiles,
      };

      await uploadToS3(s3Data);
      await updateProject(s3Data);

      setProjectData(prevData => ({
        ...prevData,
        projectUuid: projectNumber,
      }));

      setIsEditMode(true);
      console.log("Project saved successfully");
    } catch (error) {
      console.error("Failed to save project:", error);
    }
  };

  const handleSubmitProject = async (updateProgress) => {
    try {
      setProjectData(prevData => ({ ...prevData, projectDeployed: true }));
      
      const undeployedServices = projectData.completeServiceDefinitions.filter(def => !def.deployed);
      const servicesPayload = createServicesPayload(projectData, undeployedServices);
      
      const totalServices = servicesPayload.length;
      
      for (let i = 0; i < totalServices; i++) {
        updateProgress(i + 1, totalServices);
        
        const response = await createServiceRequest(servicesPayload[i]);
        if (response.response !== "Success") {
          throw new Error(`Failed to create ticket ${i + 1}`);
        }
        
        // Delay for 1.5 seconds before the next API call
        await new Promise(resolve => setTimeout(resolve, 1500));
      }
  
      setProjectData(prevData => ({
        ...prevData,
        completeServiceDefinitions: prevData.completeServiceDefinitions.map(def => ({ ...def, deployed: true })),
      }));
  
      await handleSaveProject();
      console.log("Project Tickets Created Successfully");
    } catch (error) {
      console.error("Error creating project tickets:", error);
    }
  };

  const handleStatusChange = (event) => {
    setProjectData(prevData => ({
      ...prevData,
      projectStatus: event.target.value,
    }));
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {isEditMode ? "Edit Project" : "Create New Project"}
        </Typography>

        <ProjectDetailsForm
          projectData={projectData}
          setProjectData={setProjectData}
          isEditMode={isEditMode}
          locationsData={locationsData}
        />

        <ProjectMembersSection
          projectData={projectData}
          setProjectData={setProjectData}
          allUsers={allUsers.response}
        />
        <ServiceGroupsSection
          projectData={projectData}
          setProjectData={setProjectData}
          locationsData={locationsData}
        />
        <ServiceDefinitionsSection
          projectData={projectData}
          setProjectData={setProjectData}
        />

        <ProjectFilesSection
          projectData={projectData}
          setProjectData={setProjectData}
        />
        
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="project-status-label">Project Status</InputLabel>
            <Select
              labelId="project-status-label"
              id="project-status"
              value={projectData.projectStatus}
              label="Project Status"
              onChange={handleStatusChange}
            >
              {srStatus.status.map((status) => (
                <MenuItem key={status.name} value={status.name}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <ProjectActions
          isEditMode={isEditMode}
          handleSaveProject={handleSaveProject}
          handleSubmitProject={handleSubmitProject}
        />
      </CardContent>
    </Card>
  );
};

export default ProjectCreation;