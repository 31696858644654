import React from 'react';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';

const ActionButtons = ({
    onSave,
    onClear,
    isValid,
    connectionStatus,
    isSaving
}) => {
    return (
        <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            <Button
                variant="outlined"
                startIcon={<DeleteOutlineIcon />}
                onClick={onClear}
                disabled={isSaving}
            >
                Clear
            </Button>
            <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSave}
                disabled={!isValid || connectionStatus !== 'connected' || isSaving}
                color="primary"
            >
                {isSaving ? 'Saving...' : 'Save Project'}
            </Button>
        </Box>
    );
};

export default ActionButtons;