import dayjs from "dayjs";

export const calculateAvailability = (scheduledDates, projectStart, projectEnd) => {
  if (!projectStart || !projectEnd) return 100;

  const start = dayjs(projectStart);
  const end = dayjs(projectEnd);

  if (!scheduledDates || scheduledDates.length === 0) return 100;

  const totalProjectDays = end.diff(start, "day") + 1;
  let unavailableDays = 0;

  scheduledDates.forEach((schedule) => {
    if (!schedule.projectDates) return;

    const scheduleStart = dayjs(schedule.projectDates.startDate);
    const scheduleEnd = dayjs(schedule.projectDates.endDate);

    if (scheduleEnd.isAfter(start) && scheduleStart.isBefore(end)) {
      const overlapStart = scheduleStart.isAfter(start) ? scheduleStart : start;
      const overlapEnd = scheduleEnd.isBefore(end) ? scheduleEnd : end;
      unavailableDays += overlapEnd.diff(overlapStart, "day") + 1;
    }
  });

  if (totalProjectDays <= 0) return 100;
  const availabilityPercentage =
    ((totalProjectDays - unavailableDays) / totalProjectDays) * 100;
  return Math.max(0, Math.min(100, availabilityPercentage));
};

export const calculateSkillScore = (skills) => {
  if (!skills) return 0;
  const skillValues = Object.values(skills);
  return skillValues.reduce((sum, value) => sum + value, 0) / skillValues.length;
};

export const processTechnicians = (techsData, startDate, endDate, selectedMembers) => {
  if (!Array.isArray(techsData) || !startDate || !endDate) {
    return { rankedTechnicians: [], filteredTechnicians: [] };
  }

  const processedTechs = techsData.map((tech) => {
    const availability = calculateAvailability(
      tech.Scheduled,
      startDate,
      endDate
    );
    const skillScore = tech.skillScore || calculateSkillScore(tech.Skills);
    const overallScore = (skillScore + availability) / 2;

    return {
      ...tech,
      availability,
      skillScore,
      overallScore,
    };
  });

  const rankedTechs = [...processedTechs].sort(
    (a, b) => b.overallScore - a.overallScore
  );

  const filteredTechs = rankedTechs.filter(
    (tech) => !selectedMembers.some((member) => member.Email === tech.Email)
  );

  return { rankedTechnicians: rankedTechs, filteredTechnicians: filteredTechs };
};

export const filterTechniciansBySearch = (rankedTechnicians, selectedMembers, searchInput) => {
  if (searchInput.trim() === "") {
    return rankedTechnicians.filter(
      (tech) => !selectedMembers.some((member) => member.Email === tech.Email)
    );
  }

  const searchTerm = searchInput.toLowerCase();
  return rankedTechnicians.filter(
    (tech) =>
      !selectedMembers.some((member) => member.Email === tech.Email) &&
      (tech.Name.toLowerCase().includes(searchTerm) ||
        tech.Email.toLowerCase().includes(searchTerm))
  );
};

export const validateProjectData = (projectData) => {
  const {
    projectName,
    selectedCustomer,
    startDate,
    endDate,
    selectedMembers,
    selectedServices
  } = projectData;

  if (!projectName.trim()) {
      throw new Error("Project name is required");
  }
  if (!selectedCustomer) {
    throw new Error("Customer selection is required");
  }
  if (!startDate || !endDate) {
    throw new Error("Both start and end dates are required");
  }
  if (endDate.isBefore(startDate)) {
    throw new Error("End date cannot be before start date");
  }
  if (selectedMembers.length === 0) {
    throw new Error("At least one team member is required");
  }
  if (selectedServices.length === 0) {
    throw new Error("At least one service is required");
  }
};

export const formatProjectData = (data) => {
  const {
    projectNumber,
    projectName,
    selectedCustomer,
    projectDescription,
    startDate,
    endDate,
    user,
    selectedMembers,
    selectedServices
  } = data;

  return {
    projectNumber,
    projectName,
    customer: selectedCustomer,
    description: projectDescription,
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    createdBy: user?.EmailAddress,
    createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    status: "DRAFT",
    teamMembers: selectedMembers.map((member) => ({
      email: member.Email,
      name: member.Name,
      skillScore: member.skillScore,
      availability: member.availability,
    })),
    services: selectedServices.map((serviceEntry) => ({
      serviceId: serviceEntry.service.serviceID,
      serviceType: serviceEntry.service['Service Type'],
      category: serviceEntry.service.category,
      description: serviceEntry.service.Description,
      tasks: serviceEntry.tasks.map(task => ({
        taskId: task.ID,
        name: task.name,
        description: task.description
      }))
    }))
  };
};