import React, { useEffect, useState } from 'react';
import { useUser } from '../../../UserContext';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { getAssignedTicketsCounts } from '../../ApiServices/ApiServices';

const Dashboard = () => {
  const { user } = useUser();
  const [ticketData, setTicketData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTicketCounts = async () => {
      if (user?.EmailAddress) {
        try {
          setIsLoading(true);
          setError(null);
          
          const response = await getAssignedTicketsCounts(user.EmailAddress);
          const parsedData = JSON.parse(response.body);
          
          if (!parsedData?.counts) {
            throw new Error("Invalid data format received from server");
          }

          setTicketData(parsedData.counts);
        } catch (error) {
          console.error("Error fetching ticket counts:", error);
          setError("Failed to fetch ticket data. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTicketCounts();
  }, [user]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const dataset = ticketData ? [
    { type: `Incidents (${ticketData.incidents})`, value: ticketData.incidents },
    { type: `Service Requests (${ticketData.serviceRequests})`, value: ticketData.serviceRequests },
    { type: `Tasks (${ticketData.tasks})`, value: ticketData.tasks }
  ] : [];

  return (
    <Box sx={{ p: 3, maxWidth: 'xl', mx: 'auto' }}>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Assigned Tickets
        </Typography>

        {ticketData ? (
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Current Ticket Overview
              </Typography>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Total Assigned Tickets: {ticketData.incidents + ticketData.serviceRequests + ticketData.tasks}
                </Typography>
              </Box>
              <Box sx={{ 
                height: 400, 
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <BarChart
                  xAxis={[{
                    scaleType: 'band',
                    dataKey: 'type',
                    tickLabelStyle: {
                      angle: 0,
                      textAnchor: 'middle',
                      fontSize: 12
                    },
                  }]}
                  series={[{
                    dataKey: 'value',
                    label: 'Number of Tickets',
                    color: '#2196f3',
                    highlightScope: {
                      highlighted: 'series',
                      faded: 'global'
                    }
                  }]}
                  dataset={dataset}
                  colors={['red', 'blue', 'green']}
                  height={300}
                  width={600}
                  margin={{ top: 40, bottom: 30, left: 40, right: 10 }}
                  barLegend
                  barRadius={4}
                  barWidth={30}
                />
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Alert severity="info">
            No ticket data available to display.
          </Alert>
        )}
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;