import React, { useState, useEffect } from "react";
import ResizableTextField from "./ResizableTextField"; // Import the ResizableTextField component
import "./notesForm.css"; // Stylesheet for the component
import { useParams } from "react-router-dom";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { updateTaskNotes } from "../../../ApiServices/ApiServices"; // Import the
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const NotesForm = ({ userEmail, task, serviceID }) => {
    const { taskID } = useParams();
    const [note, setNote] = useState("");
    const [log, setLog] = useState([]);

    useEffect(() => {
        const parsed = JSON.parse(task.NotesLog);
        setLog(parsed.notes);
    }, [task]);

    const handleChange = (value) => {
        setNote(value); // Update the note value when the text field changes
    };

    const handleSubmit = async () => {
        console.log("Submitting note:", note); // Log the note before submission
        if (note.trim() !== "") {
            const timestamp = new Date().toLocaleString();
            const newLogEntry = {
                notes: note,
                timestamp: timestamp,
                user: userEmail,
            };
            console.log("New log entry:", newLogEntry); // Log the new entry before adding to log state
            setLog([...log, newLogEntry]); // Add the new log entry to the log state
            try {
                await updateTaskNotes(serviceID, taskID, note, userEmail);
                console.log("Note submitted successfully");
                alert("Note submitted successfully");
                setNote(""); // Clear the note field after successful submission
            } catch (error) {
                console.error("Error submitting note:", error.message);
                alert("Failed to submit note");
            }
        }
    };

    return (
        <div className="notes-form">
            <ResizableTextField
                label="Notes"
                value={note}
                onChange={handleChange}
            />
            <button className="submit-button" onClick={handleSubmit}>
                Submit Notes
            </button>
            <div>
                <Typography variant="h6">Task Log</Typography>
                <List>
                    {log.map((entry, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography variant="body1">
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                fontWeight="bold"
                                            >
                                                {entry.timestamp}
                                            </Typography>
                                            {" - "}
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                color="primary"
                                            >
                                                {entry.user}
                                            </Typography>
                                            {": " + entry.notes}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
};

export default NotesForm;
