import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Box,
  Typography,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { checkProjectAvailability } from '../../ApiServices/ApiServices';

const ProjectNameInput = memo(({
  projectName,
  onProjectNameChange,
  maxLength = 64,
  disabled = false,
  required = true,
  error: externalError = '',
  onValidityChange = () => {},
}) => {
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [error, setError] = useState('');

  const validateProjectName = useCallback((name) => {
    if (!name && required) return 'Project name is required';
    if (name.length > maxLength) return `Project name must not exceed ${maxLength} characters`;
    if (!/^[a-zA-Z0-9-_\s]+$/.test(name)) return 'Project name can only contain letters, numbers, spaces, hyphens, and underscores';
    return '';
}, [maxLength, required]);

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value.trim();
    const validationError = validateProjectName(newValue);
    
    setError(validationError);
    setIsAvailable(null);
    onValidityChange(!validationError);
    onProjectNameChange(e);
  }, [validateProjectName, onValidityChange, onProjectNameChange]);

  const checkAvailability = useCallback(async () => {
    if (!projectName || error) {
      return;
    }

    setIsChecking(true);
    try {
      const response = await checkProjectAvailability(projectName);
      setIsAvailable(response.body.available);
      onValidityChange(response.body.available);
      
      if (!response.body.available) {
        setError('Project name is already taken');
      } else {
        setError('');
      }
    } catch (err) {
      console.error('Error checking availability:', err);
      setError('Failed to check project name availability');
      setIsAvailable(false);
      onValidityChange(false);
    } finally {
      setIsChecking(false);
    }
  }, [projectName, error, onValidityChange]);

  const displayError = error || externalError;

  const shouldDisableCheck = !projectName || 
    Boolean(error) || 
    isChecking || 
    projectName.length === 0;

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
      <Box sx={{ flex: 1 }}>
        <TextField
          fullWidth
          label="Project Name"
          value={projectName}
          onChange={handleInputChange}
          error={Boolean(displayError)}
          helperText={
            displayError || 
            `${projectName.length}/${maxLength} characters${isAvailable ? ' - Name is available' : ''}`
          }
          disabled={disabled}
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isChecking && <CircularProgress size={20} />}
                {!isChecking && isAvailable !== null && (
                  <IconButton edge="end" disabled>
                    {isAvailable ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          placeholder="Enter project name"
          sx={{
            mb: 1,
            '& .MuiInputBase-input': {
              height: '1.4375em',
            }
          }}
        />
      </Box>
      <Button
        variant="contained"
        onClick={checkAvailability}
        disabled={shouldDisableCheck}
        sx={{ 
          mt: 1,
          minWidth: '140px',
          height: '40px'
        }}
      >
        {isChecking ? 'Checking...' : 'Check Name'}
      </Button>
    </Box>
  );
});

ProjectNameInput.propTypes = {
  projectName: PropTypes.string.isRequired,
  onProjectNameChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  onValidityChange: PropTypes.func,
};

ProjectNameInput.displayName = 'ProjectNameInput';

export default ProjectNameInput;