import React from "react";
import { Box, Typography, Alert, CircularProgress } from "@mui/material";
import TC_Main from "./TC_Main";
import { calculateAvailability } from "./availabilityCalculator";
import PropTypes from "prop-types";
const TechnicianList = ({
  technicians,
  onAddMember,
  selectedMembers = [],
  startDate,
  endDate,
  onTechniciansUpdate,
  isLoading = false,
}) => {
  // Early return for loading state
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Early return for empty technicians array
  if (!technicians || technicians.length === 0) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Available Technicians
        </Typography>
        <Alert severity="info">No technicians are currently available.</Alert>
      </Box>
    );
  }

  // Filter out already selected technicians and calculate availability
  const availableTechnicians = technicians
    .filter(
      (tech) => !selectedMembers.some((member) => member.Email === tech.Email)
    )
    .map((tech) => ({
      ...tech,
      availability: calculateAvailability(
        startDate?.format("YYYY-MM-DD"),
        endDate?.format("YYYY-MM-DD"),
        tech.Scheduled || []
      ),
    }));

  // Handler for updating individual technician data
  // In TechnicianList.js
  const handleTechnicianUpdate = (updatedTechnician) => {
    if (typeof onTechniciansUpdate === 'function') {
        console.log("Yes it's a function:");
    }
    console.log("Updated Technician X:", updatedTechnician);
    console.log("IF ONTECH:", onTechniciansUpdate);
    if (onTechniciansUpdate) {
        console.log("Proceeding to Update Technician:");
      const updatedTechnicians = technicians.map((tech) =>
        tech.Email === updatedTechnician.Email ? updatedTechnician : tech
      );
      console.log("Running On Tech Update w/ new Technicians:", updatedTechnicians);
      onTechniciansUpdate(updatedTechnicians);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      {/* Header Section */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Available Technicians ({availableTechnicians.length})
      </Typography>

      {/* Date Warning */}
      {(!startDate || !endDate) && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Please select project start and end dates to see technician
          availability.
        </Alert>
      )}

      {/* Technician Cards */}
      {availableTechnicians.length > 0 ? (
        availableTechnicians.map((tech, index) => (
          <TC_Main
            key={tech.Email}
            technician={tech}
            index={index}
            totalTechnicians={availableTechnicians.length}
            onAddMember={onAddMember}
            isSelected={selectedMembers.some(
              (member) => member.Email === tech.Email
            )}
            startDate={startDate}
            endDate={endDate}
            onTechnicianUpdate={handleTechnicianUpdate}
          />
        ))
      ) : (
        <Alert severity="info" sx={{ mt: 2 }}>
          All technicians have been added to the project.
        </Alert>
      )}
    </Box>
  );
};

// PropTypes for better documentation and runtime checking
TechnicianList.propTypes = {
  technicians: PropTypes.arrayOf(
    PropTypes.shape({
      Email: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Scheduled: PropTypes.array,
      Skills: PropTypes.object,
      Role: PropTypes.string,
    })
  ).isRequired,
  onAddMember: PropTypes.func.isRequired,
  selectedMembers: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onTechniciansUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

// Default props
TechnicianList.defaultProps = {
  selectedMembers: [],
  startDate: null,
  endDate: null,
  isLoading: false,
};

export default TechnicianList;
