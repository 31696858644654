import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DarkModeOutlined,
  WbSunnyOutlined,
} from "@mui/icons-material";
import { signOut } from 'aws-amplify/auth';
import icon from "./icon.png";

const ResponsiveAppBar = ({ toggleColorMode, colorMode, versionNumber, menuItems, hasAccess }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    handleClose();
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      handleClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.mode === "light"
          ? theme.palette.background.default
          : theme.palette.background.paper,
        color: theme.palette.mode === "light"
          ? "black"
          : theme.palette.text.primary,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
        width: '100%'
      }}
    >
      <Toolbar 
        sx={{ 
          minHeight: { xs: 48, sm: 56, md: 64 }, 
          py: 0.5,
          px: { xs: 1, sm: 2, md: 3 }
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          flexGrow: 1,
          gap: 2
        }}>
          <img
            src={icon}
            alt="Logo"
            style={{ 
              marginRight: "10px",
              height: isXsScreen ? '24px' : isSmScreen ? '32px' : '40px',
              width: 'auto'
            }}
          />
          {!isSmScreen && (
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                flexGrow: 1,
                fontSize: isMdScreen ? '1rem' : '1.25rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 500
              }}
            >
              Cloud Ingenuity Service Desk
            </Typography>
          )}
        </Box>
        
        <Box sx={{ 
          display: "flex", 
          alignItems: "center", 
          gap: { xs: 0.5, sm: 1, md: 2 }
        }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              display: { xs: 'none', sm: 'block' },
              fontSize: isMdScreen ? '0.7rem' : '0.8rem',
              opacity: 0.8
            }}
          >
            v{versionNumber}
          </Typography>
          <IconButton 
            onClick={toggleColorMode} 
            color="inherit"
            size={isXsScreen ? 'small' : 'medium'}
            sx={{ 
              transition: 'transform 0.2s',
              '&:hover': { transform: 'scale(1.1)' }
            }}
          >
            {colorMode === "dark" ? <DarkModeOutlined /> : <WbSunnyOutlined />}
          </IconButton>
          <IconButton
            size={isXsScreen ? 'small' : 'medium'}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
            sx={{ 
              transition: 'transform 0.2s',
              '&:hover': { transform: 'scale(1.1)' }
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu 
          anchorEl={anchorEl} 
          open={open} 
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
              mt: 1.5,
              maxHeight: '80vh',
              '& .MuiMenuItem-root': {
                px: 2,
                py: 1,
                typography: 'body2',
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                }
              },
              '& .MuiDivider-root': {
                my: 1
              }
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {menuItems.map((item) => (
            hasAccess(item.access) && (
              <MenuItem 
                key={item.route} 
                onClick={() => handleMenuItemClick(item.route)}
              >
                {item.label}
              </MenuItem>
            )
          ))}
          <Divider />
          <MenuItem 
            onClick={handleSignOut}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.error.light + '20'
              }
            }}
          >
            Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;