import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Avatar,
    IconButton,
    Tooltip,
    useTheme,
    CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { motion } from 'framer-motion';

const SelectedMembers = ({ members, onRemoveMember }) => {
    const theme = useTheme();

    const getCardColor = (index) => {
        const hue = (index / (Math.max(members.length - 1, 1))) * 240;
        return theme.palette.mode === 'light'
            ? `hsla(${hue}, 60%, 95%, 0.2)`
            : `hsla(${hue}, 60%, 25%, 0.2)`;
    };

    const getTextColor = (index) => {
        const hue = (index / (Math.max(members.length - 1, 1))) * 240;
        return theme.palette.mode === 'light'
            ? `hsl(${hue}, 60%, 30%)`
            : `hsl(${hue}, 60%, 70%)`;
    };

    if (!members || members.length === 0) {
        return (
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Selected Project Members</Typography>
                <Card sx={{ backgroundColor: theme.palette.background.paper, opacity: 0.7 }}>
                    <CardContent>
                        <Typography variant="body1" color="textSecondary" align="center">
                            No team members selected yet
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Selected Project Members</Typography>
            {members.map((member, index) => (
                <motion.div
                    key={member.Email}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.3 }}
                >
                    <Card 
                        sx={{
                            marginBottom: 2,
                            backgroundColor: getCardColor(index),
                            color: getTextColor(index)
                        }}
                    >
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar 
                                        sx={{ 
                                            width: 50, 
                                            height: 50, 
                                            bgcolor: getTextColor(index) 
                                        }}
                                    >
                                        {member.Name.split(' ').map(n => n[0]).join('')}
                                    </Avatar>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                                        {member.Name}
                                    </Typography>
                                    <Typography variant="body2">
                                        {member.Email}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ 
                                        position: 'relative', 
                                        display: 'inline-flex', 
                                        width: 60, 
                                        height: 60,
                                        mr: 1
                                    }}>
                                        <CircularProgress
                                            variant="determinate"
                                            value={member.overallScore}
                                            size={60}
                                            thickness={4}
                                            sx={{ color: getTextColor(index) }}
                                        />
                                        <Box sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <Typography variant="body2" component="div">
                                                {`${Math.round(member.overallScore)}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Remove Member">
                                        <IconButton 
                                            onClick={() => onRemoveMember(member.Email)}
                                            sx={{ color: getTextColor(index) }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </motion.div>
            ))}
        </Box>
    );
};

export default SelectedMembers;