import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import AWS from "aws-sdk";
import {
    Card,
    CardContent,
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Box,
    Container,
    Stack,
    Chip,
    FormControl,
    InputLabel,
    Backdrop,
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import {
    fetchUsers,
    fetchTask,
    updatePriority,
    updateAssignee,
    getTaskAttachmentIDs,
    updateTaskFileIDs,
    updateTaskNotes,
    updateTaskStatus,
    fetchProjectFromS3,
} from "../../ApiServices/ApiServices";
import NotesForm from "../Components/TaskNotesForm/NotesForm";
import TaskUploadButton from "../Components/UploadButton/TaskUploadButton";
import statusData from "../Data/status.json";
import priority from "../Data/priority.json";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import { v4 as uuidv4 } from "uuid";
import { format, parseISO } from "date-fns";
import { downloadData, getUrl, uploadData } from "aws-amplify/storage";

const Task = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const licenseKey =
        "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";
    const { taskID, serviceID } = useParams();
    const [task, setTask] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [currentUser, setUser] = useState("");
    const [userInfo, setUsers] = useState();
    const [selectedPriority, setSelectedPriority] = useState("");
    const [selectedAssignee, setSelectedAssignee] = useState("");
    const [life, setLife] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [taskMembers, setTaskMembers] = useState([]);
    const [projectFiles, setProjectFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState(null);

    const bucketName = "ciservicedesk";

    useEffect(() => {
        const fetchData = async () => {
            setIsApiLoading(true);
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                setUser(idToken.payload.email);
                const usersResponse = await fetchUsers(licenseKey);
                setUsers(usersResponse);
                const taskResponse = await fetchTask(serviceID, taskID);
                console.log("Task Response: ", taskResponse);
                setTask(taskResponse.body);
                const history = JSON.parse(taskResponse?.body?.Lifespan);
                setLife(history.status);
                setSelectedStatus(taskResponse?.body.TaskStatus || "");
                setSelectedPriority(taskResponse?.body.Priority || "");
                setSelectedAssignee(taskResponse?.body.AssignedTo ?? "");
                setStatusOptions(statusData.status);

                const parsedTaskMembers = JSON.parse(
                    taskResponse?.body?.TaskMembers || "[]",
                );
                setTaskMembers(parsedTaskMembers);
                const attachmentIDs = JSON.parse(
                    taskResponse?.body.AttachmentIDs,
                );
                console.log("AttachmentIDs:", attachmentIDs);
                if (attachmentIDs && attachmentIDs.length > 0) {
                    const fetchedFiles = await fetchFilesFromS3(attachmentIDs);
                    setProjectFiles(fetchedFiles);
                }
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsApiLoading(false);
                setLoading(false);
            }
        };
        fetchData();
    }, [taskID, serviceID]);

    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        try {
            const date = parseISO(dateString);
            return format(date, "M/dd/yyyy");
        } catch (error) {
            console.error("Error formatting date:", error);
            return dateString; // Return the original string if parsing fails
        }
    };

    const fetchFilesFromS3 = async (fileIDs) => {
        const files = [];
        for (const fileID of fileIDs) {
            try {
                const filePath = `projectFiles/${fileID.uuid}_${fileID.name}`;
                const response = await downloadData({
                    key: filePath,
                }).result;

                console.log("File to Get From S3:", fileID);
                console.log("S3 Response:", response);

                const bodyBlob = await response.body.blob();
                if (bodyBlob) {
                    files.push({
                        name: fileID.name,
                        uuid: fileID.uuid,
                        type: fileID.type,
                    });
                }
            } catch (error) {
                console.error(`Error fetching file ${fileID}:`, error);
            }
        }
        return files;
    };

    const handleStatusChange = async (newValue) => {
        setSelectedStatus(newValue);
        setIsApiLoading(true);
        try {
            await updateTaskStatus(taskID, serviceID, newValue, currentUser);
            alert("Task Status Updated Successfully");
            const updatedTask = await fetchTask(serviceID, taskID);
            setTask(updatedTask.body);
            const updatedHistory = JSON.parse(updatedTask.body.Lifespan);
            setLife(updatedHistory.status);
        } catch (error) {
            console.error("Error Updating Status:", error);
            alert("Failed to update Task Status. Please try again.");
        } finally {
            setIsApiLoading(false);
        }
    };

    const handlePriorityChange = async (newValue) => {
        setSelectedPriority(newValue);
        setIsApiLoading(true);
        try {
            await updatePriority(taskID, newValue, currentUser);
            alert("Task Priority Updated Successfully");
        } catch (error) {
            console.error("Error Updating Priority:", error);
            alert("Failed to update Task Priority. Please try again.");
        } finally {
            setIsApiLoading(false);
        }
    };

    const handleAssignmentChange = async (newValue) => {
        setSelectedAssignee(newValue);
        setIsApiLoading(true);
        try {
            await updateAssignee(taskID, newValue, currentUser);
            alert("Assignee Updated Successfully");
        } catch (error) {
            console.error("Error Updating Assignee:", error);
            alert("Failed to update Assignee. Please try again.");
        } finally {
            setIsApiLoading(false);
        }
    };

    const getStatusColor = (statusText) => {
        const statusObject = statusData.status.find(
            (item) => item.name === statusText,
        );
        return statusObject ? statusObject.color : "white";
    };

    const getPriorityColor = (priorityText) => {
        const priorityObject = priority.find(
            (item) => item.priority === priorityText,
        );
        return priorityObject ? priorityObject.color : "white";
    };

    const statusColor = useMemo(
        () => getStatusColor(selectedStatus),
        [selectedStatus],
    );
    const priorityColor = useMemo(
        () => getPriorityColor(selectedPriority),
        [selectedPriority],
    );

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileExtension = file.name.split(".").pop().toLowerCase();
        const uuid = uuidv4();
        const newFileName = `${uuid}_${file.name}`;

        try {
            const result = await uploadData({
                key: `projectFiles/${newFileName}`,
                data: file,
                options: {
                    bucket: "CiServiceDesk",
                },
            }).result;
            console.log(`File Uploaded Successfully: ${result}`);
            const newFile = {
                name: file.name,
                uuid: uuid,
                type: fileExtension,
            };
            const updatedProjectFiles = [...projectFiles, newFile];
            setProjectFiles(updatedProjectFiles);

            // Update task file IDs in the database
            await updateTaskFileIDs(taskID, serviceID, updatedProjectFiles);

            alert("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Failed to upload file. Please try again.");
        }
    };

    const getFileIcon = (fileType) => {
        switch (fileType) {
            case "pdf":
                return <PictureAsPdfIcon />;
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <ImageIcon />;
            case "doc":
            case "docx":
                return <DescriptionIcon />;
            case "xls":
            case "xlsx":
                return <InsertDriveFileIcon style={{ color: "#217346" }} />;
            default:
                return <InsertDriveFileIcon />;
        }
    };

    const handlePreviewFile = (file) => {
        console.log("File to Preview:", file);
        setPreviewFile(file);
    };

    const handleClosePreview = () => {
        setPreviewFile(null);
    };

    const RenderFilePreview = ({ file }) => {
        const [fileUrl, setFileUrl] = useState(null);
        useEffect(() => {
            if (!file) return;
            const fetchFileUrl = async () => {
                const url = await getUrl({
                    key: `projectFiles/${file.uuid}_${file.name}`,
                });
                setFileUrl(url.url.toString());
            };

            fetchFileUrl();
        }, [file]);

        if (!file) return null;
        console.log("Preview File URL:", fileUrl);

        switch (file.type.toLowerCase()) {
            case "pdf":
                return (
                    <Box
                        sx={{
                            width: "100%",
                            height: "70vh",
                            overflow: "hidden",
                        }}
                    >
                        <iframe
                            src={fileUrl}
                            width="100%"
                            height="100%"
                            title="PDF Preview"
                            style={{ border: "none" }}
                        />
                    </Box>
                );
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return (
                    <Box
                        sx={{
                            width: "100%",
                            height: "70vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={fileUrl}
                            alt={file.name}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                        />
                    </Box>
                );
            default:
                return (
                    <Typography>
                        Preview not available for this file type.{" "}
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download file
                        </a>
                    </Typography>
                );
        }
    };

    const handleRemoveFile = async (uuid) => {
        const updatedProjectFiles = projectFiles.filter(
            (file) => file.uuid !== uuid,
        );
        setProjectFiles(updatedProjectFiles);

        // Update task file IDs in the database
        const fileIDs = updatedProjectFiles.map((file) => file.uuid);
        await updateTaskFileIDs(serviceID, taskID, fileIDs);

        // TODO: Implement S3 file deletion if required
    };

    const handleDownloadFile = async (file) => {
        const fileUrl = await getUrl({
            key: `projectFiles/${file.uuid}_${file.name}`,
        });
        window.open(fileUrl.url.toString(), "_blank");
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ pt: 3 }}>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isApiLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">
                                    Task Details
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Task Type:</Typography>
                                    <Typography>
                                        {task?.TaskType || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Task Number:</Typography>
                                    <Typography color="#b0d2ff">
                                        {task?.TaskNumber || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Organization:</Typography>
                                    <Typography color="#8abcff">
                                        {task?.Organization || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Building:</Typography>
                                    <Typography color="#549eff">
                                        {task?.Building || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Floor:</Typography>
                                    <Typography color="#338bff">
                                        {task?.Floor || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Created:</Typography>
                                    <Typography color="#0064e8">
                                        {task?.CreationTime}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Due Date:</Typography>
                                    <Typography color="#boe4dd">
                                        {task?.DueDate}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Last Updated:</Typography>
                                    <Typography color="#b0d2ff">
                                        {task?.LastUpdated}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Impact:</Typography>
                                    <Typography color="#8abcff">
                                        {task?.Impact || "N/A"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Level:</Typography>
                                    <Typography color="#8abcff">
                                        {task?.Level || "N/A"}
                                    </Typography>
                                </Box>
                                <Typography>{task?.TaskDescription}</Typography>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={selectedStatus}
                                        onChange={(e) =>
                                            handleStatusChange(e.target.value)
                                        }
                                        sx={{
                                            color: statusColor,
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: statusColor,
                                                },
                                        }}
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem
                                                key={option.name}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Priority</InputLabel>
                                    <Select
                                        value={selectedPriority}
                                        onChange={(e) =>
                                            handlePriorityChange(e.target.value)
                                        }
                                        sx={{
                                            color: priorityColor,
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: priorityColor,
                                                },
                                        }}
                                    >
                                        {priority.map((option) => (
                                            <MenuItem
                                                key={option.priority}
                                                value={option.priority}
                                            >
                                                {option.priority}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">
                                    Assigned To
                                </Typography>
                                <Typography>
                                    Assignment Group: {task?.AssignmentGroup}
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel>Assignee</InputLabel>
                                    <Select
                                        value={selectedAssignee}
                                        onChange={(e) =>
                                            handleAssignmentChange(
                                                e.target.value,
                                            )
                                        }
                                    >
                                        {taskMembers.map((member) => (
                                            <MenuItem
                                                key={member.EmailAddress}
                                                value={member.EmailAddress}
                                            >
                                                {`${member.FirstName} ${member.LastName} (${member.EmailAddress})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">Task Log</Typography>
                                {task && (
                                    <NotesForm
                                        userEmail={currentUser}
                                        task={task}
                                        serviceID={task.RequestID}
                                    />
                                )}
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">
                                    Task Lifecycle
                                </Typography>
                                {life.map((entry, index) => (
                                    <Typography key={index}>
                                        <strong>{entry.status}</strong> -{" "}
                                        {entry.timestamp}: {entry.user}
                                    </Typography>
                                ))}
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Typography variant="h6">Task Files</Typography>
                                <input
                                    accept="*/*"
                                    style={{ display: "none" }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<UploadFileIcon />}
                                    >
                                        Upload File
                                    </Button>
                                </label>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>File Type</TableCell>
                                                <TableCell>File Name</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projectFiles.map((file) => (
                                                <TableRow key={file.uuid}>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                handlePreviewFile(
                                                                    file,
                                                                )
                                                            }
                                                        >
                                                            {getFileIcon(
                                                                file.type,
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        {file.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDownloadFile(
                                                                    file,
                                                                )
                                                            }
                                                        >
                                                            <DownloadIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveFile(
                                                                    file.uuid,
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                open={!!previewFile}
                onClose={handleClosePreview}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "none",
                        height: isSmallScreen ? "100%" : "auto",
                        maxHeight: isSmallScreen ? "none" : "90vh",
                    },
                }}
            >
                <DialogTitle>{previewFile?.name}</DialogTitle>
                <DialogContent>
                    <RenderFilePreview file={previewFile} />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default Task;
